.q0XS9W_container {
  max-width: 363px;
}

.q0XS9W_textSizeH200 {
  font-size: 12px;
  font-weight: var(--ds-font-weight-semibold, 600);
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  line-height: 16px;
}

.q0XS9W_topDescription {
  padding: calc(var(--jpo-common-base-spacing) * 2);
  background-color: var(--ds-background-neutral, var(--adg3-color-N20));
}

.q0XS9W_descriptionParagraph:not(:last-child) {
  margin-bottom: var(--jpo-common-base-spacing);
}

.q0XS9W_configurationSection {
  padding: calc(var(--jpo-common-base-spacing) * 2);
}

.q0XS9W_overwriteTitle {
  font-size: 14px;
  font-weight: var(--ds-font-weight-semibold, 600);
  color: var(--ds-text, var(--adg3-color-N800));
  margin-bottom: var(--jpo-common-base-spacing);
  line-height: 16px;
}

.q0XS9W_overwriteDescription {
  margin-bottom: var(--jpo-common-base-spacing);
}

.q0XS9W_configurationTableHeader {
  border-bottom: 1px solid var(--ds-border, var(--adg3-color-N30));
  display: flex;
}

.q0XS9W_optionsHeader {
  flex: 1 1 0;
}

.q0XS9W_issueValuesHeader {
  flex: 1.5 1 0;
  display: flex;

  & > div {
    margin: var(--jpo-common-base-spacing) 0;
    text-align: center;
    flex: 1 1 0;
  }
}

.q0XS9W_configurationTableContent {
  margin-top: calc(var(--jpo-common-base-spacing) * .5);
  margin-bottom: calc(var(--jpo-common-base-spacing) * 3);
}

.q0XS9W_configurationOptionRow {
  display: flex;
}

.q0XS9W_optionsContent {
  margin: var(--jpo-common-base-spacing) 0;
  flex: 1 1 0;
}

.q0XS9W_issueValueSelection {
  flex: 1.5 1 0;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.q0XS9W_actionFooter {
  text-align: right;
}

.q0XS9W_selectedIssueCount {
  background-color: var(--ds-background-neutral-bold, var(--adg3-color-N500));
  color: var(--ds-text-inverse, var(--adg3-color-N0));
  border-radius: 3px;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 10px;
  display: flex;
}

.viPLjG_optimizeButtonAfterIcons {
  align-items: center;
  display: flex;
}

.viPLjG_spinnerContainer {
  margin-left: calc(var(--jpo-common-base-spacing) / 2);
}

.viPLjG_optimizeButtonIconOnly {
  display: flex;
}

.viPLjG_optimizeButtonLabelOnly {
  display: none;
}

@media (width >= 1366px) {
  .viPLjG_optimizeButtonIconOnly {
    display: none;
  }

  .viPLjG_optimizeButtonLabelOnly {
    display: flex;
  }
}

.RCUxaq_container {
  justify-content: flex-end;
  display: flex;
}

.RCUxaq_label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.RCUxaq_label:first-letter {
  text-transform: capitalize;
}

.RCUxaq_avatar {
  margin-left: 5px;
}

.oAtGSG_crossProjectReleaseTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Od-IXa_issueTitle {
  align-items: center;
  display: flex;
}

.Od-IXa_issueIcon {
  width: var(--jpo-icon-size-small);
  min-width: var(--jpo-icon-size-small);
  height: var(--jpo-icon-size-small);
  margin-right: 5px;
}

.Od-IXa_issueSummary {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 5px;
  overflow: hidden;
}

._8mBR-W_releaseTitle {
  align-items: center;
  display: flex;
}

._8mBR-W_projectIcon {
  height: 16px;
  margin: 2px var(--jpo-common-base-spacing) 2px 3px;
  background-size: 16px 16px;
  flex: 0 0 16px;
}

._8mBR-W_titleText {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: auto;
  overflow: hidden;

  & a {
    cursor: pointer;
  }
}

._5tEYsq_sprintTitle {
  align-items: center;
  display: flex;
}

._5tEYsq_projectIcon {
  height: 24px;
  margin: 2px var(--jpo-common-base-spacing) 2px 0;
  flex: 0 0 24px;
}

._5tEYsq_titleText {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: auto;
  overflow: hidden;

  & a {
    cursor: pointer;
  }
}

._5-wTfW_teamTitle {
  align-items: center;
  display: flex;
}

._5-wTfW_teamIcon {
  background-image: url("team.8d6e3095.svg");
  background-size: 16px 16px;
  flex: 0 0 16px;
  height: 16px;
  margin: 2px 5px 2px 1px;
}

._5-wTfW_titleText {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: auto;
  overflow: hidden;
}

._5-wTfW_defaultTeamAvatar {
  background-color: var(--ds-background-neutral, var(--adg3-color-N40));
  border-radius: 50%;
  margin: 2px 5px 2px 1px;
  padding: 2px;

  & > span {
    width: 12px;
    height: 12px;
    display: flex;
  }
}

.J-Y8fq_title {
  vertical-align: top;
  line-height: 20px;
  display: inline-block;
}

.J-Y8fq_avatar {
  display: inline-block;
}

._9m15JG_component {
  margin-right: 5px;
}

._9m15JG_componentRemoved span {
  text-decoration: line-through;
}

._9m15JG_tagContainer {
  display: flex;
}

._9m15JG_componentsContainer {
  flex-direction: row;
  display: flex;
}

.Fod5uq_title {
  vertical-align: top;
  line-height: 20px;
  display: inline-block;
}

.Fod5uq_avatar {
  display: inline-block;
}

.-\31 QI8a_issueTitle {
  align-items: center;
  display: flex;
}

.-\31 QI8a_issueIcon {
  width: var(--jpo-icon-size-small);
  min-width: var(--jpo-icon-size-small);
  height: var(--jpo-icon-size-small);
  margin-right: 5px;
}

.-\31 QI8a_issueLinkType {
  margin-right: 5px;
}

.-\31 QI8a_issueSummary {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 5px;
  overflow: hidden;
}

.jhcJ8q_title {
  vertical-align: top;
  line-height: 20px;
  display: inline-block;
}

.jhcJ8q_avatar {
  display: inline-block;
}

.I5-f4G_name {
  vertical-align: top;
  line-height: 20px;
  display: inline-block;
}

.I5-f4G_avatar {
  display: inline-block;
}

.Kph_na_multiple-label {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-style: italic;
}

.ihzk7q_title-group {
  padding-right: var(--jpo-common-base-spacing);
  align-items: center;
  display: flex;
}

.ihzk7q_avatar {
  flex: 0 0 16px;
  display: flex;
}

.ihzk7q_title {
  margin-left: var(--jpo-common-base-spacing);
  flex: auto;
}

.ihzk7q_defaultTeamAvatar {
  background-color: var(--ds-background-neutral, var(--adg3-color-N40));
  border-radius: 50%;
  margin: 2px;
  padding: 2px;

  & > span {
    width: 12px;
    height: 12px;
    display: flex;
  }
}

.WoPb7q_icon {
  width: var(--jpo-icon-size-medium);
  min-width: var(--jpo-icon-size-medium);
  height: var(--jpo-icon-size-medium);
  cursor: pointer;
  padding: 0;
  display: inline-block;
}

.WoPb7q_toggle {
  vertical-align: middle;
  font-size: unset;
  color: var(--ds-text, var(--adg3-color-N900));
  background-color: #0000;
  border: none;
  outline: none;
  margin-left: -7px;
}

.WoPb7q_label {
  vertical-align: middle;
  line-height: 24px;
  display: inline-block;
}

.WoPb7q_label:hover {
  text-decoration: underline;
}

.WoPb7q_multiple {
  cursor: pointer;
  color: var(--ds-text-brand, var(--adg3-color-B400));
  outline-offset: 0 !important;
}

.WoPb7q_attribute-with-parenthetical {
  white-space: nowrap;
  max-width: 100%;
  display: inline-flex;
}

.WoPb7q_ellipsed {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.WoPb7q_parenthetical {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  flex: auto;
  display: inline-flex;
  overflow: hidden;

  & > .WoPb7q_ellipsed {
    flex: auto;
  }
}

.ldgrhG_container {
  margin: calc(2 * var(--jpo-common-base-spacing)) 0;
  flex: auto;
}

.ldgrhG_table > div:first-child {
  border-bottom: 2px solid var(--ds-border, var(--adg3-color-N40));
}

.ldgrhG_grid:focus {
  outline: none;
}

.ldgrhG_tableRow {
  box-sizing: border-box;
  align-items: center;
  display: flex;
}

.ldgrhG_ReactVirtualized__Table__headerRow.ldgrhG_tableRow {
  padding-bottom: 2px;
}

.ldgrhG_columnHeader {
  box-sizing: border-box;
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-size: 12px;
  font-weight: var(--ds-font-weight-semibold, 600);
  text-transform: none;
}

.ldgrhG_tableRow > div:first-child {
  margin-left: 0;
  margin-right: 3px;
}

.ldgrhG_modifiedColumnHeader {
  outline: none;
  justify-content: flex-end;
  display: flex;

  & > span {
    margin-right: 10px;
    position: relative;
    overflow: visible;
  }

  & > span:before {
    content: " ";
    border-style: solid;
    border-width: 3px;
    border-image: initial;
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    bottom: 10px;
    right: -8px;
  }

  & > span:after {
    content: " ";
    border-style: solid;
    border-width: 3px;
    border-image: initial;
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    bottom: 2px;
    right: -8px;
  }

  & > span[data-sortdir="ASC"]:before {
    border-color: transparent transparent var(--ds-border, var(--adg3-color-N40));
    border-bottom: 3px solid var(--ds-border, var(--adg3-color-N40));
  }

  & > span[data-sortdir="ASC"]:after {
    border-color: var(--ds-border, var(--adg3-color-N300)) transparent transparent;
    border-top: 3px solid var(--ds-border, var(--adg3-color-N300));
  }

  & > span[data-sortdir="DESC"]:before {
    border-color: transparent transparent var(--ds-border, var(--adg3-color-N300));
    border-bottom: 3px solid var(--ds-border, var(--adg3-color-N300));
  }

  & > span[data-sortdir="DESC"]:after {
    border-color: var(--ds-border, var(--adg3-color-N40)) transparent transparent;
    border-top: 3px solid var(--ds-border, var(--adg3-color-N40));
  }
}

.ldgrhG_gridContainer {
  margin: calc(2 * var(--jpo-common-base-spacing)) 0;
  flex: auto;
  overflow-y: auto;
}

.ldgrhG_modifiedGridColumnHeader {
  font: inherit;
  cursor: pointer;
  outline: inherit;
  float: right;
  outline-offset: 0px;
  box-sizing: border-box;
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-size: 12px;
  font-weight: var(--ds-font-weight-semibold, 600);
  text-transform: none;
  background: none;
  border: none;
  margin-top: 1px;
  margin-right: 2px;
  padding: 0;
  display: flex;

  & > span {
    margin-right: 10px;
    position: relative;
    overflow: visible;
  }

  & > span:before {
    content: " ";
    border-style: solid;
    border-width: 3px;
    border-image: initial;
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    bottom: 10px;
    right: -8px;
  }

  & > span:after {
    content: " ";
    border-style: solid;
    border-width: 3px;
    border-image: initial;
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    bottom: 2px;
    right: -8px;
  }

  & > span[data-sortdir="ASC"]:before {
    border-color: transparent transparent var(--ds-border, var(--adg3-color-N40));
    border-bottom: 3px solid var(--ds-background-accent-gray-bolder, var(--adg3-color-N40));
  }

  & > span[data-sortdir="ASC"]:after {
    border-color: var(--ds-border, var(--adg3-color-N300)) transparent transparent;
    border-top: 3px solid var(--ds-border, var(--adg3-color-N300));
  }

  & > span[data-sortdir="DESC"]:before {
    border-color: transparent transparent var(--ds-border, var(--adg3-color-N300));
    border-bottom: 3px solid var(--ds-border, var(--adg3-color-N300));
  }

  & > span[data-sortdir="DESC"]:after {
    border-color: var(--ds-border, var(--adg3-color-N40)) transparent transparent;
    border-top: 3px solid var(--ds-background-accent-gray-bolder, var(--adg3-color-N40));
  }
}

.ldgrhG_modifiedGridColumnHeader:focus-visible {
  outline: 2px solid var(--ds-border-focused, #4c9aff);
  outline-offset: 0px;
}

.ldgrhG_commonColumnHeader {
  outline: none;
  justify-content: flex-start;
  display: flex;

  & > span {
    margin-right: 10px;
    position: relative;
    overflow: visible;
  }
}

.ldgrhG_oldColumnHeader {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ldgrhG_oldColumnHeaderArrow {
  margin-bottom: -2px;
  margin-right: 5px;
}

.ldgrhG_hideScrollBar {
  overflow: "hidden";
  position: "relative";
  width: "100%";
}

.bnOPCW_filterButtonWrapper > button {
  text-align: left;
  width: 278px;
}

.j-gf7G_projectTitleContainer {
  height: calc(1.5 * var(--jpo-icon-size-small));
  align-items: center;
  display: flex;
}

.j-gf7G_xsmall-spacing {
  padding-left: calc(.5 * var(--jpo-common-base-spacing));
}

.j-gf7G_small-spacing {
  padding-left: var(--jpo-common-base-spacing);
}

.j-gf7G_projectTitle {
  box-sizing: border-box;
  padding-right: calc(3 * var(--jpo-common-base-spacing));
  max-width: calc(450px - calc(5.5 * var(--jpo-common-base-spacing))  - calc(1.5 * var(--jpo-icon-size-small)));
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 0 auto;
  overflow: hidden;
}

:root {
  --dialog-menu-container-max-width: 450px;
}

.oVM_OG_checkboxLabel {
  padding-right: calc(2 * var(--jpo-common-base-spacing));
  max-width: calc(var(--dialog-menu-container-max-width)  - calc(6 * var(--jpo-common-base-spacing))  - calc(1.5 * var(--jpo-icon-size-small)));
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

.oVM_OG_noMatchFoundText {
  margin: var(--jpo-common-base-spacing) 0;
  padding: var(--jpo-common-base-spacing) calc(2 * var(--jpo-common-base-spacing));
  color: var(--ds-text-disabled, var(--adg3-color-N200));
  text-align: center;
}

.oVM_OG_teamAvatarItem {
  align-items: center;
  gap: var(--jpo-common-base-spacing);
  display: flex;
}

.oVM_OG_defaultTeamAvatar {
  background-color: var(--ds-background-neutral, var(--adg3-color-N40));
  border-radius: 50%;
  margin: 0 2px;
  padding: 4px;

  & > span {
    display: flex;
  }
}

._5f2lVa_filterButtonWrapper > button {
  text-align: left;
  width: 320px;
}

._5f2lVa_buttonTextContainer {
  display: flex;
}

._5f2lVa_buttonLabel {
  padding-right: var(--jpo-common-base-spacing);
}

.WF5SHW_container {
  background-color: var(--ds-background-neutral, var(--adg3-color-N40));
  border-radius: 5px;
  height: 10px;
  display: flex;
}

.WF5SHW_bar {
  background-color: var(--ds-background-neutral-bold, var(--adg3-color-N500));
  border-radius: 5px;
}

.EEOLLq_container {
  flex-direction: column;
  display: flex;
}

.EEOLLq_header {
  justify-content: space-between;
  margin-bottom: 2px;
  display: flex;
}

.p0eKqa_container {
  min-width: 350px;
}

.p0eKqa_spinnerWrapper {
  margin-right: 4px;
  display: flex;
}

.p0eKqa_labelWrapper {
  align-items: center;
  display: flex;
}

.UZNJmq_footer {
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.UZNJmq_footerLeft {
  flex-grow: 1;
}

.UZNJmq_progressContainer {
  justify-content: center;
  align-items: center;
  gap: var(--jpo-common-base-spacing);
  flex-shrink: 1;
  display: flex;
}

.UZNJmq_commitProgress {
  margin: 0 calc(2 * var(--jpo-common-base-spacing));
  display: inline-block;
}

.UZNJmq_buttonWrapper {
  justify-content: space-between;
  align-items: center;
  gap: calc(.5 * var(--jpo-common-base-spacing));
  cursor: pointer;
  display: flex;
}

._93vqlG_content {
  padding: calc(3 * var(--jpo-common-base-spacing));
  flex: 1;
}

._93vqlG_shareButton {
  margin-left: var(--jpo-common-base-spacing);
}

._93vqlG_contentHeading {
  margin-bottom: calc(3 * var(--jpo-common-base-spacing));
}

._93vqlG_contentBody {
  margin: 10px 0;
}

._93vqlG_contentBodyIntro {
  margin-bottom: calc(4 * var(--jpo-common-base-spacing));
}

._93vqlG_contentFooter {
  text-align: right;
  margin-top: calc(3 * var(--jpo-common-base-spacing));
}

._93vqlG_urlForm {
  align-items: center;
  margin: 10px 0;
  display: flex;
}

._93vqlG_urlTextfield {
  flex-grow: 1;
  margin-right: 5px;
}

._93vqlG_copyButton {
  flex-grow: 0;
}

._93vqlG_copiedMessage {
  width: 50px;
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-size: 12px;
  font-weight: var(--ds-font-weight-semibold, 600);
  flex-grow: 0;
  margin-left: 10px;
}

._93vqlG_accessControl {
  margin-top: 20px;
  display: flex;
}

._93vqlG_accessControlMessage {
  flex-grow: 1;
  margin-left: 10px;
}

._93vqlG_accessControlMacroIcon {
  margin-top: -2px;
}

._93vqlG_modalBody {
  background: var(--ds-surface-overlay, var(--adg3-color-N0));
  border-radius: 3px;
  display: flex;
}

._93vqlG_generateLinkButton {
  margin-right: var(--jpo-common-base-spacing);
}

._93vqlG_sideNavigation {
  width: 240px;
}

._93vqlG_shareButtonIconOnly {
  display: flex;
}

._93vqlG_shareButtonLabelOnly {
  display: none;
}

@media (width >= 1366px) {
  ._93vqlG_shareButtonIconOnly {
    display: none;
  }

  ._93vqlG_shareButtonLabelOnly {
    display: flex;
  }
}

.g8i1ia_container {
  min-height: 200px;
}

.g8i1ia_containerOld {
  min-height: 200px;
  padding-bottom: 10px;
}

.g8i1ia_errorMessage {
  color: var(--ds-text-danger, var(--adg3-color-R400));
  margin-top: 5px;
  font-size: 15px;
}

.g8i1ia_previewContainer {
  background-color: var(--ds-background-neutral-bold, var(--adg3-color-N500));
  margin-top: 16px;
  position: relative;
}

.g8i1ia_previewContainerOld {
  background-color: var(--ds-background-neutral-bold, var(--adg3-color-N500));
  margin-top: 16px;
}

.g8i1ia_previewPaneOld {
  min-height: 400px;
  padding: 50px 20px;
}

.g8i1ia_previewPane {
  justify-content: center;
  align-items: center;
  min-height: 200px;
  padding: 50px 20px;
  display: flex;
}

.g8i1ia_previewBadge {
  background-color: var(--ds-background-neutral-bold-hovered, var(--adg3-color-N700));
  color: var(--ds-text-inverse, var(--adg3-color-N0));
  opacity: .8;
  border-radius: 3px;
  margin: 10px;
  padding: 5px;
  font-size: 13px;
  display: flex;
  position: absolute;

  & svg {
    stroke: var(--ds-icon-inverse, var(--adg3-color-N0));
  }
}

.g8i1ia_spinnerContainer {
  margin-right: 5px;
}

.g8i1ia_previewImageOld {
  object-fit: contain;
  width: 100%;
  height: 400px;
}

.g8i1ia_previewImage {
  object-fit: contain;
  flex: 1;
  width: 100%;
}

.g8i1ia_collapsedIframeOld {
  visibility: collapse;
}

.g8i1ia_collapsedIframe {
  visibility: collapse;
  position: absolute;
  top: 0;
  left: 0;
}

.g8i1ia_footer {
  justify-content: flex-end;
  margin-top: 10px;
  display: flex;
}

.g8i1ia_warningMessage {
  align-items: center;
  gap: 4px;
  display: flex;
}

.FQwbrG_timelineSettingsFieldset {
  border: none;
  margin-top: 7px;
  margin-bottom: -2px;
}

.FQwbrG_timelineSettingsLegend {
  font-size: 12px;
  font-weight: var(--ds-font-weight-semibold, 600);
  color: var(--ds-text-subtle, #6b778c);
  margin-bottom: 4px;
  padding: 0;
}

.Brmfbq_isTransparentBackground > div {
  background-color: #0000;
  border-color: #0000;
}

.Brmfbq_isAlignRight input {
  text-align: right;
}

.Brmfbq_error > div {
  border-color: var(--ds-border-danger, var(--adg3-color-R400));
  box-sizing: border-box;
  margin-right: 1px;
}

.Brmfbq_isReadOnly input {
  cursor: default;
}

.pakj-q_tabs-row-wrapper {
  & > div {
    flex-direction: row;
  }

  & > div > div:first-child {
    background: var(--ds-background-neutral, var(--adg3-color-N20));
  }

  & > div > div:first-child > span {
    display: none;
  }

  & > div > div:first-child > div {
    padding: 0 calc(2 * var(--jpo-common-base-spacing)) calc(2 * var(--jpo-common-base-spacing));
    flex-direction: column;
    width: 240px;
    display: flex;
  }
}

.pakj-q_tabs-container {
  padding-inline: var(--ds-space-100, 8px);
  width: 100%;
}

.BubBCW_select-option {
  align-items: center;
  display: flex;
}

.BubBCW_avatar {
  margin-right: 8px;
  display: inline-flex;
}

.BubBCW_label {
  align-items: center;
  line-height: 20px;
  display: inline-flex;
  overflow: hidden;
}

.BubBCW_truncated {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.BubBCW_issue-icon {
  width: var(--jpo-icon-size-small);
  min-width: var(--jpo-icon-size-small);
  height: var(--jpo-icon-size-small);
  margin-right: 5px;
}

.BubBCW_issue-summary {
  word-break: break-all;
  margin-left: 5px;
  overflow: hidden;
}

.jh2iBq_ellipsed {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.qqZ2EW_container {
  padding: var(--jpo-common-base-spacing) 0;
  max-height: 40vh;
  overflow: hidden auto;
}

.qqZ2EW_item {
  line-height: initial;
  padding: var(--jpo-common-base-spacing) calc(var(--jpo-common-base-spacing) * 2);
}

.qqZ2EW_group-heading {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-size: 11px;
  line-height: initial;
  text-transform: uppercase;
  font-weight: var(--ds-font-weight-bold, bold);
  padding: calc(var(--jpo-common-base-spacing) * 2) calc(var(--jpo-common-base-spacing) * 2) var(--jpo-common-base-spacing);
}

.NKUPUW_option {
  align-items: center;
  line-height: 1.1;
  display: flex;
}

.NKUPUW_optionAvatar {
  margin-right: var(--jpo-common-base-spacing);
  display: flex;
}

.NKUPUW_optionEmail {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-size: 12px;
}

.NKUPUW_optionUnassigned {
  align-items: center;
  display: flex;
}

.R8WuXW_issueTitle {
  margin-top: 5px;
  display: flex;
}

.R8WuXW_issueIcon {
  width: var(--jpo-icon-size-small);
  min-width: var(--jpo-icon-size-small);
  height: var(--jpo-icon-size-small);
  margin-top: 2px;
  margin-right: 5px;
}

.R8WuXW_issueSummary {
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 250px;
  margin-left: 5px;
  overflow: hidden;
}

.NbYNVa_filters {
  align-items: center;
  font-size: 11px;
  display: flex;
}

.NbYNVa_filterDetails {
  justify-content: space-between;
  display: flex;
}

.NbYNVa_messageText {
  margin-bottom: 4px;
}

.fVtzqW_list {
  flex-direction: column;
  display: flex;
}

.fVtzqW_row {
  align-content: center;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.fVtzqW_swatch {
  border-radius: 3px;
  width: 16px;
  height: 16px;
}

.fVtzqW_allOtherIssuesButton {
  background: none;
}

.fVtzqW_componentSelect {
  width: 100%;
}

.fVtzqW_allOtherIssues {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  padding-left: 12px;
}

.fVtzqW_addLabelButton button {
  margin-left: -8px;
}

.fVtzqW_addLabel {
  padding-left: 6px;
}

.Xirf0q_list {
  flex-direction: column;
  display: flex;
}

.Xirf0q_row {
  align-content: center;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.Xirf0q_swatch {
  border-radius: 3px;
  width: 16px;
  height: 16px;
}

.Xirf0q_allOtherIssuesButton {
  background: none;
}

.Xirf0q_issueTypeSelect {
  width: 100%;
}

.Xirf0q_allOtherIssues {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  padding-left: 12px;
}

.Xirf0q_addColourLink {
  margin-left: -12px;
}

.Xirf0q_issueTypeOption {
  align-items: center;
  line-height: 16px;
  display: flex;
}

.Xirf0q_issueTypeOptionIcon {
  background-repeat: no-repeat;
  background-size: contain;
  width: 12px;
  height: 12px;
  padding-right: 4px;
}

.Xirf0q_projectHeading {
  align-items: center;
  display: flex;
}

.Xirf0q_projectName {
  margin-left: 4px;
}

.dYaZ6G_list {
  flex-direction: column;
  display: flex;
}

.dYaZ6G_row {
  align-content: center;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.dYaZ6G_swatch {
  border-radius: 3px;
  width: 16px;
  height: 16px;
}

.dYaZ6G_allOtherIssuesButton {
  background: none;
}

.dYaZ6G_labelSelect {
  width: 100%;
}

.dYaZ6G_allOtherIssues {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  padding-left: 12px;
}

.dYaZ6G_addLabelButton button {
  margin-left: -8px;
}

.dYaZ6G_addLabel {
  padding-left: 6px;
}

.tsWTQG_list {
  flex-direction: column;
  display: flex;
}

.tsWTQG_row {
  align-content: center;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.tsWTQG_swatch {
  border-radius: 3px;
  width: 16px;
  height: 16px;
}

.tsWTQG_addColourLink {
  margin-left: -12px;
}

.tsWTQG_allOtherIssuesButton {
  background: none;
}

.tsWTQG_prioritySelect {
  width: 100%;
}

.tsWTQG_allOtherIssues {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  padding-left: 12px;
}

.tsWTQG_priorityOption {
  align-items: center;
  line-height: 16px;
  display: flex;
}

.tsWTQG_priorityOptionIcon {
  background-repeat: no-repeat;
  background-size: contain;
  width: 12px;
  height: 12px;
  padding-right: 4px;
}

.Z0-zja_list {
  flex-direction: column;
  display: flex;
}

.Z0-zja_row {
  align-content: center;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.Z0-zja_swatch {
  border-radius: 3px;
  width: 16px;
  height: 16px;
}

.Z0-zja_allOtherIssuesButton {
  background: none;
}

.Z0-zja_labelSelect {
  width: 100%;
}

.Z0-zja_allOtherIssues {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  padding-left: 12px;
}

.Z0-zja_addLabelButton button {
  margin-left: -8px;
}

.Z0-zja_addLabel {
  padding-left: 6px;
}

.LpNIna_option {
  justify-content: space-between;
  display: flex;
}

.Dx8POa_checkboxWrapper {
  padding-top: calc(2 * var(--jpo-common-base-spacing));
  margin-left: -8px;
}

.taeYma_group {
  align-items: center;
  display: flex;
  position: relative;

  & > .taeYma_icon {
    padding-top: 2px;
  }
}

.taeYma_groupTitleSection {
  padding-left: calc(var(--jpo-common-base-spacing) * 1.5);
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  flex: 1 1 0;
  align-items: center;
  max-width: 264px;
  display: flex;
}

.taeYma_expandCollapseChevron {
  cursor: pointer;
  position: absolute;
  top: 8px;
  left: -25px;
}

.taeYma_groupTitle {
  flex: 1 1 0;
  min-width: 85%;

  & > form > div {
    margin-top: 0;
    margin-left: -8px;
  }
}

.taeYma_groupTitleReadViewContainer {
  color: var(--ds-text, var(--adg3-color-N900));
  min-height: 1.42857em;
  padding: var(--jpo-common-base-spacing) 6px;
  word-break: break-all;
  line-height: 1.42857;
  display: flex;
}

.taeYma_groupTitleReadViewContainer > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.taeYma_trashButton {
  text-align: right;
  flex: 1 1 0;
}

.epJkjG_blue {
  color: var(--ds-text-brand, var(--adg3-color-B400));
}

.epJkjG_row {
  & > td {
    padding: 0 var(--jpo-common-base-spacing);
  }
}

.epJkjG_componentGroup {
  align-items: center;
  display: flex;
  position: relative;

  & > .epJkjG_icon {
    padding-top: 2px;
  }
}

.epJkjG_groupTitleSection {
  padding-left: calc(var(--jpo-common-base-spacing) * 1.5);
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  flex: 1 1 0;
  align-items: center;
  max-width: 264px;
  display: flex;
}

.epJkjG_expandCollapseChevron {
  position: absolute;
  top: 8px;
  left: -25px;
}

.epJkjG_groupTitle {
  flex: 1 1 0;
  min-width: 85%;

  & input {
    cursor: initial;
  }
}

.epJkjG_trashButton {
  text-align: right;
  flex: 1 1 0;
}

.epJkjG_componentSelect {
  margin-left: 36px;
}

._6yml3W_row > td {
  padding: 0 var(--jpo-common-base-spacing);
}

._6yml3W_customFieldValuesGroup {
  align-items: center;
  display: flex;
  position: relative;

  & > ._6yml3W_icon {
    padding-top: 2px;
  }
}

._6yml3W_groupTitle {
  flex: 1 1 0;
  min-width: 85%;

  & input {
    cursor: initial;
  }
}

._6yml3W_customFieldValueSelect {
  margin-left: 36px;
}

.x5vVhW_blue {
  color: var(--ds-text-brand, var(--adg3-color-B400));
}

.x5vVhW_row {
  & > td {
    padding: 0 var(--jpo-common-base-spacing);
  }
}

.x5vVhW_labelGroup {
  align-items: center;
  display: flex;
  position: relative;

  & > .x5vVhW_icon {
    padding-top: 2px;
  }
}

.x5vVhW_groupTitleSection {
  padding-left: calc(var(--jpo-common-base-spacing) * 1.5);
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  flex: 1 1 0;
  align-items: center;
  max-width: 264px;
  display: flex;
}

.x5vVhW_expandCollapseChevron {
  position: absolute;
  top: 8px;
  left: -25px;
}

.x5vVhW_groupTitle {
  flex: 1 1 0;
  min-width: 85%;

  & input {
    cursor: initial;
  }
}

.x5vVhW_trashButton {
  text-align: right;
  flex: 1 1 0;
}

.x5vVhW_labelSelect {
  margin-left: 36px;
}

.xVA1UG_description {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  margin-left: 25px;
  font-size: 13px;
}

.xVA1UG_checkboxWrapper {
  margin-left: -8px;
}

.xVA1UG_option + .xVA1UG_option {
  margin-top: calc(1.5 * var(--jpo-common-base-spacing));
}

.hzFZ5q_settingsList {
  -ms-overflow-style: auto;
  max-height: calc(100vh - 256px);
  overflow-y: auto;

  & tbody {
    border: 0;
  }
}

.hzFZ5q_checkboxInfo {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  margin-left: calc(3 * var(--jpo-common-base-spacing));
  font-size: 13px;
}

.hzFZ5q_clearAllButton {
  text-align: right;
}

.hzFZ5q_showCapacityCheckbox {
  margin-left: calc(var(--jpo-common-base-spacing) * -1);
}

.hzFZ5q_showAggregateToggle {
  margin-left: calc(var(--jpo-common-base-spacing) * -1);
  margin-bottom: calc(var(--jpo-common-base-spacing) * .75);
}

.hzFZ5q_viewSettingSelect {
  width: 100%;
}

.hzFZ5q_rollupSetting {
  min-width: 100px;
}

.hzFZ5q_row {
  & td {
    padding-bottom: calc(2 * var(--jpo-common-base-spacing));
  }

  &:last-child td {
    padding-bottom: 0;
  }
}

.hzFZ5q_groupByRow {
  & td {
    padding-bottom: 2px;
  }

  &:last-child td {
    padding-bottom: 0;
  }
}

.hzFZ5q_topAlignedTd {
  vertical-align: top;
}

.IIii6q_noMatchFoundText {
  margin: var(--jpo-spacing) 0;
  padding: var(--jpo-spacing) calc(2 * var(--jpo-spacing));
  color: var(--ds-text-disabled, var(--adg3-color-N200));
  text-align: center;
}

.IIii6q_optionLabel {
  word-break: break-all;
  text-overflow: ellipsis;
  max-width: 236px;
  display: inline-block;
  overflow: hidden;
}

.o5zuRq_filterButtonWrapper > button {
  text-align: left;
  width: 278px;
}

.o5zuRq_filterButton {
  align-items: center;
  display: flex;
}

.o5zuRq_filterButtonApplied {
  font-weight: var(--ds-font-weight-bold, bold);
}

.o5zuRq_buttonText {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.o5zuRq_issueSelector {
  min-width: 232px;
  padding-left: 44px;
  padding-right: 24px;
  line-height: normal;
}

.o5zuRq_includeTransitive {
  padding-left: 36px;
  padding-right: 24px;
}

.SBxuMW_hierarchyFilter {
  align-items: center;
  display: inline-flex;

  & button[aria-haspopup] {
    text-align: left;
    width: 135px;
  }

  & button {
    min-width: 135px;
  }
}

.SBxuMW_separator {
  padding: 0 calc(var(--jpo-common-base-spacing) / 2);
}

.mo_vea_crossProjectReleaseOption {
  padding-right: var(--jpo-common-base-spacing);
  max-width: calc(450px - calc(6 * var(--jpo-common-base-spacing))  - calc(1.5 * var(--jpo-icon-size-small)));
  display: flex;
}

.mo_vea_crossProjectReleaseName {
  margin-right: var(--jpo-common-base-spacing);
  flex: 1;
  min-width: 50px;
}

.mo_vea_crossProjectReleaseLozenge {
  flex: 0;
}

.gJJ7dW_sprint-title-container {
  max-width: calc(450px - calc(6 * var(--jpo-common-base-spacing))  - calc(1.5 * var(--jpo-icon-size-small)));
  padding-right: calc(2 * var(--jpo-common-base-spacing));
  align-items: center;
  display: flex;
}

.gJJ7dW_sprint-title {
  padding-right: var(--jpo-common-base-spacing);
}

.POzVCG_categoriesLabel > span {
  align-items: baseline;
  display: inline-flex;
}

._6cBvHG_filtersDialog {
  width: 496px;
}

._6cBvHG_filtersHeader {
  padding: calc(var(--jpo-common-base-spacing) / 2) calc(var(--jpo-common-base-spacing) * 2);
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

._6cBvHG_filtersList {
  -ms-overflow-style: auto;
  max-height: calc(100vh - 313px);
  padding: 0 calc(var(--jpo-common-base-spacing) * 3) var(--jpo-common-base-spacing);
  margin-top: 0;
  overflow-y: auto;
}

._6cBvHG_filterOptions {
  border-top: 1px solid var(--ds-border, var(--adg3-color-N40));
  height: 40px;
  padding-left: var(--jpo-common-base-spacing);
  align-items: center;
  margin-top: 0;
  display: flex;
}

._6cBvHG_filterRow {
  margin-bottom: var(--jpo-common-base-spacing);
  align-items: center;
  display: flex;

  &:first-child {
    margin-bottom: calc(var(--jpo-common-base-spacing) * 3);
  }
}

._6cBvHG_filterRowLabel {
  padding-right: calc(var(--jpo-common-base-spacing) * 2);
  flex: 0 0 154px;
}

._6cBvHG_hierarchyFilterLabel {
  align-self: flex-end;
  line-height: 32px;
}

._6cBvHG_filterRowFilter {
  flex: 1;
}

._3aa0cW_searchFieldContainer input {
  text-overflow: ellipsis;
}

._3aa0cW_clearSearchField {
  padding-right: 14px;
}

._3aa0cW_clearSearchField > button {
  display: flex;
}

._3aa0cW_clearSearchField span:hover {
  color: var(--ds-text-disabled, var(--adg3-color-N50));
}

._3aa0cW_hasInput input {
  padding-right: var(--jpo-common-base-spacing);
}

.LFMl9q_issueTitle {
  background-color: var(--ds-background-neutral, var(--adg3-color-N10));
  cursor: pointer;
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 8px;
  display: flex;
}

.LFMl9q_issueTitle:hover {
  background-color: var(--ds-background-neutral-subtle-hovered, var(--adg3-color-N20));
}

.LFMl9q_issueIcon {
  width: var(--jpo-icon-size-small);
  min-width: var(--jpo-icon-size-small);
  height: var(--jpo-icon-size-small);
  margin-top: 2px;
  margin-right: 5px;
}

.LFMl9q_issueSummary {
  word-break: break-all;
  min-width: 250px;
  margin-left: 5px;
}

._6nmt9a_affectedIssuesCount {
  padding-right: 0;
}

._6nmt9a_disabledWarningButton {
  padding: 0;
}

.Tcej8q_warningsButtonIconOnly {
  display: flex;
}

.Tcej8q_warningsButtonIconLabel {
  display: none;
}

@media (width >= 1366px) {
  .Tcej8q_warningsButtonIconOnly {
    display: none;
  }

  .Tcej8q_warningsButtonIconLabel {
    display: flex;
  }
}

.FM3apa_viewsListSkeleton {
  min-width: 300px;
  height: 165px;
}

.FM3apa_viewsList {
  -ms-overflow-style: auto;
  min-width: 250px;
  max-height: 180px;
  overflow: hidden auto;
}

.FM3apa_modifiedViewName {
  color: var(--ds-text, var(--adg3-color-text-light));
}

.FM3apa_modifiedViewActions {
  padding: calc(var(--jpo-common-base-spacing) / 2) var(--jpo-common-base-spacing) 0 0;
  font-size: 14px;

  & > :not(:last-child) {
    margin-right: var(--jpo-common-base-spacing);
  }
}

.FM3apa_actionSeparator {
  background: var(--ds-text-subtle, var(--adg3-color-N500));
  vertical-align: middle;
  border-radius: 3px;
  width: 3px;
  height: 3px;
  display: inline-block;
}

.FM3apa_lozengeContainer {
  margin-left: calc(var(--jpo-common-base-spacing) * 2);
}

@media (height >= 650px) {
  .FM3apa_viewsList {
    max-height: 260px;
  }
}

@media (height >= 750px) {
  .FM3apa_viewsList {
    max-height: 300px;
  }
}

@media (height >= 820px) {
  .FM3apa_viewsList {
    max-height: 340px;
  }
}

.FM3apa_viewName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.FM3apa_viewTrigger {
  align-items: center;
  display: flex;

  & .FM3apa_view {
    max-width: 175px;
    display: flex;

    & > span:first-child {
      flex-shrink: 1;
    }
  }
}

.FM3apa_activeLozengeContainer {
  margin-left: calc(var(--jpo-common-base-spacing) / 2);
}

@media (width >= 1280px) {
  .FM3apa_viewTrigger {
    & .FM3apa_view {
      max-width: 240px;
    }
  }
}

@media (width >= 1440px) {
  .FM3apa_viewTrigger {
    & .FM3apa_view {
      max-width: 280px;
    }
  }
}

.QjmutW_searchSummaryContainer {
  align-items: center;
  display: flex;
}

.QjmutW_summaryCount {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-size: 12px;
}

.QjmutW_searchIconWrapper {
  margin-right: 8px;
}

.QjmutW_iconWrapper {
  cursor: pointer;
  align-items: center;
  width: 20px;
  display: flex;

  &:hover svg {
    color: var(--ds-text-subtle, var(--adg3-color-N500));
  }

  & button {
    height: 24px;
  }
}

.QjmutW_justifyCentered {
  justify-content: center;
}

.QjmutW_disabled:hover svg {
  color: var(--ds-text-disabled, var(--adg3-color-N90));
}

.hacwPa_modal-body {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.x0kt5W_spaPreviewResultsOverlay {
  background-color: var(--ds-background-accent-gray-bolder, var(--adg3-color-N800));
  padding: calc(var(--jpo-common-base-spacing) * 3);
  margin-bottom: calc(var(--jpo-common-base-spacing) * 3);
  gap: calc(var(--jpo-common-base-spacing) * 1.5);
  border-radius: calc(var(--jpo-common-base-spacing) * .5);
  flex-direction: column;
  display: flex;
}

.x0kt5W_spaPreviewHeaderSection {
  display: flex;
}

.x0kt5W_previewTitle {
  font-size: 20px;
  font-weight: var(--ds-font-weight-medium, 500);
  color: var(--ds-text-inverse, var(--adg3-color-N0));
  flex: 1 1 0;
  line-height: 24px;
}

.x0kt5W_actionButtons {
  text-align: right;
  flex: 1 1 0;
}

.x0kt5W_cancelButton {
  color: var(--ds-text-inverse, var(--adg3-color-DN700));
}

.x0kt5W_spaPreviewDescription {
  color: var(--ds-text-inverse, var(--adg3-color-N0));
  font-size: 16px;
  line-height: 20px;
}

._1OEXCW_radioOptions {
  padding: var(--jpo-common-base-spacing) 0;
}

._1OEXCW_configuredDateLozenge {
  margin-left: calc(var(--jpo-common-base-spacing) * .5);
}

._1OEXCW_row td {
  padding-bottom: calc(2 * var(--jpo-common-base-spacing));
}

.TWK4FW_wireframe {
  pointer-events: none;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 100%;
  min-height: 40px;
  display: flex;

  & > div {
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px;
    overflow: hidden;
  }
}

.TWK4FW_readOnly {
  color: var(--ds-text-disabled, var(--adg3-color-N70));
}

._6DYw6W_triangle {
  z-index: 0;
  width: 10px;
  position: absolute;
  top: -5px;
  right: 0;
  overflow: hidden;
}

._6DYw6W_bar {
  z-index: 0;
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.oXsrEG_flyout {
  display: flex;

  & .oXsrEG_column {
    margin: 8px;

    & .oXsrEG_heading {
      color: var(--ds-text-subtlest, var(--adg3-color-N200));
      white-space: nowrap;
      margin-bottom: 10px;
    }
  }

  & .oXsrEG_column:nth-child(2) {
    width: 24px;
  }

  & .oXsrEG_value {
    white-space: nowrap;
  }

  & .oXsrEG_no-value {
    color: var(--ds-text-subtlest, var(--adg3-color-N200));
  }
}

.qE68GG_flyout {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  width: 100%;
  max-width: 800px;
  max-height: 400px;
  padding: var(--ds-space-200, 16px) var(--ds-space-300, 24px);
  box-sizing: border-box;
  display: inline-block;

  & tbody {
    border-bottom: none;
  }
}

.qE68GG_flyoutOld {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  width: 100%;
  display: inline-block;

  & tbody {
    border-bottom: none;
  }
}

.qE68GG_issueTitleCell {
  max-width: 400px;
}

.qE68GG_count {
  font-weight: var(--ds-font-weight-semibold, 600);
  color: var(--ds-text-subtle, var(--adg3-color-N600));
}

.qE68GG_countLabel {
  font-weight: var(--ds-font-weight-semibold, 600);
  color: var(--ds-text, var(--adg3-color-N700));
  margin-right: 5px;
}

.qE68GG_percentage {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
}

.qE68GG_totalValue {
  display: flex;
}

.qE68GG_separator {
  border-top: 1px solid var(--ds-border, var(--adg3-color-N40));
  border-bottom: none;
}

.qE68GG_issueTitle {
  align-items: center;
  display: flex;
}

.qE68GG_issueIcon {
  width: var(--jpo-icon-size-small);
  min-width: var(--jpo-icon-size-small);
  height: var(--jpo-icon-size-small);
  margin-right: 5px;
}

.qE68GG_issueSummary {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 5px;
  overflow: hidden;
}

.qE68GG_accuracyWarning {
  font-size: 12px;
}

.uwXdGW_container {
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  height: 40px;
  display: flex;

  & .uwXdGW_breakdown {
    width: 100%;

    & .uwXdGW_bar {
      border-radius: 3px;
      width: 100%;
      height: 8px;
      display: flex;
      overflow: hidden;

      & .uwXdGW_completed {
        background-color: var(--ds-border-success, var(--adg3-color-G300));
      }

      & .uwXdGW_completed.uwXdGW_overbooked {
        background-color: var(--ds-background-danger-bold, var(--adg3-color-R300));
      }

      & .uwXdGW_remaining {
        background-color: var(--ds-border, var(--adg3-color-N50));
      }

      & > * + * {
        margin-left: 2px;
      }
    }
  }
}

._8rLZ3q_breakdown-container {
  padding: 0 16px;
}

.or0DHa_tagContainer {
  align-items: center;
  display: flex;
}

.or0DHa_item {
  min-width: calc(4.5 * var(--jpo-common-base-spacing));
  margin-left: calc(.5 * var(--jpo-common-base-spacing));
}

.or0DHa_item:first-child {
  margin-left: 0;
}

.or0DHa_item > span {
  max-width: 100%;
}

.or0DHa_componentsContainer {
  padding: 0 var(--jpo-common-base-spacing);
  cursor: pointer;
  box-sizing: border-box;
  flex-direction: row;
  width: 100%;
  display: flex;

  & > div {
    max-width: calc(100% - var(--jpo-common-base-spacing));
  }
}

.or0DHa_componentsContainerDisabled {
  cursor: default;

  &:active, &:focus {
    outline: none;
  }
}

.or0DHa_hiddenContainer {
  display: none;
}

.or0DHa_disabled span {
  color: var(--ds-text-disabled, var(--adg3-color-N70));
}

.or0DHa_labelsContainer {
  & > .or0DHa_itemsWrapper > div {
    width: 100%;
    padding: 0 var(--jpo-common-base-spacing);
  }
}

.or0DHa_itemsWrapper {
  align-items: center;
  width: 100%;
  min-height: 40px;
  display: flex;
}

.Tw2-xG_cell-invalid input {
  color: var(--ds-text-danger, red);
}

.Tw2-xG_cell-valid input {
  color: var(--ds-text, var(--adg3-color-N900));
}

.Tw2-xG_isReadOnly > div > div > div > div {
  border-color: #0000 !important;
}

.Tw2-xG_wireframe {
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 100%;
  min-height: 40px;
  display: flex;

  & input {
    color: var(--ds-text, var(--adg3-color-N900));
    user-select: none;
    word-wrap: break-word;
    background-color: #0000;
    border: none;
    max-width: 100%;
    padding: 8px 9px;
    font-size: 14px;
    line-height: 24px;
    overflow: hidden;

    &:disabled {
      color: var(--ds-text-disabled, var(--adg3-color-N70));
    }
  }
}

.Tw2-xG_readOnly {
  & > div {
    color: var(--ds-text-disabled, var(--adg3-color-N70));
  }
}

.Tw2-xG_placeholder {
  color: var(--ds-text-subtlest, #757575);
}

.Tw2-xG_projectedDate {
  color: var(--ds-text-subtle, var(--adg3-color-N400));
  justify-content: space-between;
  align-items: center;
  height: 20px;
  padding: 10px;
  font-style: italic;
  display: flex;
}

.Tw2-xG_rollUpIcon {
  flex-shrink: 0;
}

.Tw2-xG_projectedDateText {
  margin-left: calc(var(--jpo-common-base-spacing) / 2);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Tw2-xG_exportPNGLozenge {
  align-items: center;
  display: flex;
}

.CgLxga_menuContainer {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.mWGF8q_addDependencyType {
  min-width: 140px;
  padding-left: 0;
  padding-right: 0;
}

.mWGF8q_addDependencyIssue {
  flex-wrap: nowrap;
  min-width: 300px;
  display: flex;
}

button.mWGF8q_addDependencyButton {
  width: 40px;
  height: 40px;
}

.m0aFTa_menuContainer {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

._2DwZiG_assignee {
  margin-top: 1px;
}

.Agj3RG_issueTitle {
  display: flex;
}

.Agj3RG_issueIcon {
  width: var(--jpo-icon-size-small);
  min-width: var(--jpo-icon-size-small);
  height: var(--jpo-icon-size-small);
  margin-top: 2px;
  margin-right: 5px;
}

.Agj3RG_issueSummary {
  word-break: break-all;
  min-width: 250px;
  margin-left: 5px;
}

.FbQQtq_warning {
  color: var(--ds-text-danger, var(--adg3-color-R300));
}

.Jh0cRW_trashcan {
  margin-top: -5px;
}

._7ne0DG_table {
  max-height: 400px;
  display: block;
  overflow-y: auto;

  & thead, & tbody {
    vertical-align: top;
    border: none;
  }

  & th {
    white-space: nowrap;
    color: var(--ds-text-subtlest, var(--adg3-color-N200));
  }

  & td, & th {
    height: 28px;
  }
}

._7ne0DG_typeCell {
  min-width: 140px;
}

._7ne0DG_assigneeCell {
  padding-top: 0;
}

._7ne0DG_change-indicator {
  width: 5px;
  min-width: 5px;
  max-width: 5px;
  padding: 0;
  display: block;
  position: relative;
}

._7ne0DG_externalDependenciesHeader {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  text-transform: uppercase;
  font-weight: var(--ds-font-weight-bold, bold);
}

._7ne0DG_footerActions {
  justify-content: space-between;
  margin-top: 20px;
  display: flex;
}

._7ne0DG_remove-filter {
  align-items: center;
  display: flex;

  & > :first-child {
    padding-right: var(--jpo-common-base-spacing);
  }
}

.azu7hG_container {
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 40px;
  display: flex;
  overflow: hidden;
}

.azu7hG_issuesCount {
  cursor: pointer;
  outline: none;
  flex: auto;
  align-items: center;
  height: 100%;
  display: flex;
}

.azu7hG_issuesLabel {
  white-space: nowrap;
  flex: auto;
  padding-left: 8px;
}

.w3jkUG_container {
  display: flex;
}

.w3jkUG_rollUp {
  color: var(--ds-text-subtlest, var(--adg3-color-N300));
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
  flex-shrink: 0;
  align-items: center;
  max-width: 50%;
  padding: 9px;
  font-style: italic;
  display: flex;
  overflow: hidden;
}

.w3jkUG_rollupValue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.w3jkUG_estimate {
  flex-grow: 1;
}

.w3jkUG_readOnly input {
  cursor: not-allowed;
  pointer-events: auto;
}

.LOCrTW_issueStatus {
  padding: 0 var(--jpo-common-base-spacing);
  min-height: 40px;
  display: flex;

  & > * {
    align-self: center;
  }
}

.JN-0Kq_select-option {
  align-items: center;
  display: flex;
}

.JN-0Kq_icon-wrapper {
  margin-right: 8px;
  display: inline-flex;
}

.JN-0Kq_label {
  display: inline-flex;
  overflow: hidden;
}

.JN-0Kq_truncated {
  text-overflow: ellipsis;
  overflow: hidden;
}

.JN-0Kq_not-in-plan {
  color: var(--ds-text-disabled, var(--adg3-color-N70));
  width: 100%;
  font-style: italic;
  display: inline-block;
}

.JN-0Kq_icon {
  background: center / contain no-repeat;
  width: 16px;
  height: 16px;
}

.hC7mga_crossProjectReleaseOption {
  display: flex;
}

.hC7mga_crossProjectReleaseName {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: var(--jpo-common-base-spacing);
  flex: 1;
  overflow: hidden;
}

.hC7mga_crossProjectReleaseLozenge {
  flex: 0;
  max-width: 100px;
}

.hC7mga_crossProjectReleaseLozenge > span {
  max-width: 100px;
}

.hC7mga_moreReleases {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--ds-text-disabled, var(--adg3-color-N70));
  overflow: hidden;
}

.CDefyW_crossProjectReleaseOption {
  display: flex;
}

.CDefyW_crossProjectReleaseName {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: var(--jpo-common-base-spacing);
  flex: 1;
  min-width: 50px;
  overflow: hidden;
}

.CDefyW_crossProjectReleaseLozenge {
  flex: 0;
  max-width: 100px;
}

.CDefyW_crossProjectReleaseLozenge > span {
  max-width: 100px;
}

.CDefyW_container {
  display: flex;
}

.CDefyW_release {
  flex-grow: 1;
}

.CDefyW_releaseDisabled {
  height: 100%;
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  padding: 0 var(--jpo-common-base-spacing);
  justify-content: center;
  align-items: center;
  display: flex;

  & p {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-style: italic;
    overflow: hidden;
  }
}

.CDefyW_infoIcon {
  margin-left: calc(var(--jpo-common-base-spacing) / 2);
  width: calc(2 * var(--jpo-common-base-spacing));
  height: calc(2 * var(--jpo-common-base-spacing));

  & span {
    width: 100%;
    height: 100%;
  }
}

.CDefyW_disabledContainer {
  color: var(--ds-text-disabled, var(--adg3-color-N70));
  height: 40px;
  padding-left: calc(var(--jpo-common-base-spacing) * 1.75);
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.pnFITa_selectRollupContainer {
  &:hover .pnFITa_onlyRollup {
    flex-basis: 0;
    width: 50%;
  }
}

.pnFITa_openedRollup {
  z-index: 1;
  position: relative;
}

.pnFITa_rollupWrapper {
  width: 100%;
  display: flex;
}

.pnFITa_disabledRollupWrapper {
  &:focus, &:active {
    outline: none;
  }
}

.pnFITa_disabledRollup {
  opacity: .5;
}

.pnFITa_childrenWrapper {
  flex: 1 1 0;
  align-items: center;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
}

.pnFITa_childrenWrapperWithRollupValue {
  justify-content: flex-end;
}

.pnFITa_placeholder {
  position: relative;
}

.pnFITa_rollup {
  padding-right: var(--jpo-common-base-spacing);
  color: var(--ds-text-subtle, var(--adg3-color-N400));
  cursor: pointer;
  flex: 1 1 0;
  align-items: center;
  width: 50%;
  font-style: italic;
  display: flex;

  &.pnFITa_onlyRollup, &.pnFITa_onlyRollupReadOnly {
    flex-basis: 100%;
    width: 100%;
  }
}

.pnFITa_rollupSmartLink {
  color: var(--ds-text-subtle, var(--adg3-color-N400));
  opacity: 1;
}

.pnFITa_rollupValue {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 2px;
  overflow: hidden;
}

.wtLFHW_externalSprint {
  padding-right: var(--jpo-common-base-spacing);
  width: 100%;
  display: flex;
}

.wtLFHW_externalSprintText {
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  min-width: 14px;
  max-width: calc(100% - 30px);
  display: inline-block;
  overflow: hidden;
}

.wtLFHW_externalSprintLozenge {
  margin-left: var(--jpo-common-base-spacing);
  flex-shrink: 0;
  align-items: baseline;
  display: inline-flex;
  overflow: hidden;

  & > div {
    padding-right: calc(.5 * var(--jpo-common-base-spacing));
  }
}

.wtLFHW_completedSprintsChunk {
  color: var(--ds-text-disabled, var(--adg3-color-N70));
}

.wtLFHW_sprintTitlePrefix {
  padding: 0 calc(.5 * var(--jpo-common-base-spacing));
}

.wtLFHW_sprintTitle {
  text-overflow: ellipsis;
  overflow: hidden;
}

.wtLFHW_tooltip {
  padding: var(--jpo-common-base-spacing);
  text-align: center;
}

.wtLFHW_tooltip > h4 {
  font-weight: var(--ds-font-weight-bold, bold);
  color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.wtLFHW_completedAndUncompletedSprints {
  display: flex;

  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.sMSUOa_teamName {
  text-align: start;
  min-height: 36px;
  padding: var(--jpo-common-base-spacing);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sMSUOa_teamAvatarItem {
  align-items: center;
  gap: var(--jpo-common-base-spacing);
  display: flex;
}

.sMSUOa_defaultTeamAvatarValue {
  background-color: var(--ds-background-neutral, var(--adg3-color-N40));
  border-radius: 50%;
  margin: 2px;
  padding: 4px;

  & > span {
    display: flex;
  }
}

.sMSUOa_defaultTeamAvatarOption {
  background-color: var(--ds-background-neutral, var(--adg3-color-N40));
  border-radius: 50%;
  margin: 2px 10px 2px 2px;
  padding: 4px;

  & > span {
    display: flex;
  }
}

.sMSUOa_teamDropdown {
  text-align: start;
  flex: auto;
  justify-content: space-between;
  display: inline-flex;
}

.sMSUOa_dropdownArrow {
  align-self: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: flex;

  & > span {
    width: 24px;
    height: 24px;
  }
}

.sMSUOa_tooltip {
  padding: var(--jpo-common-base-spacing);
  text-align: center;
}

.sMSUOa_tooltip > h4 {
  font-weight: var(--ds-font-weight-bold, bold);
  color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.sMSUOa_externalValueWrapper {
  text-overflow: ellipsis;
  vertical-align: middle;
  min-width: 24px;
  max-width: calc(100% - 30px);
  display: inline-block;
  overflow: hidden;
}

.sMSUOa_externalLozenge {
  margin-left: var(--jpo-common-base-spacing);
  margin-right: var(--jpo-common-base-spacing);
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

.sMSUOa_verifiedTeamIcon {
  vertical-align: middle;
  margin-top: 3px;
  display: inline-block;
  overflow: hidden;
}

.sMSUOa_optionWrapper {
  padding-right: var(--jpo-common-base-spacing);
}

.sMSUOa_optionNoAvatarWrapper, .sMSUOa_optionAvatarWrapper {
  align-items: center;
  display: flex;
}

.sMSUOa_selectOption {
  display: flex;
}

.sMSUOa_avatar {
  margin-right: 8px;
  display: inline-flex;
}

.sMSUOa_label {
  align-items: center;
  line-height: 20px;
  display: inline-flex;
  overflow: hidden;
}

.sMSUOa_truncated {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.t08fYq_avatarWithTitle {
  align-items: center;
  display: inline-flex;
}

.t08fYq_userTitle {
  padding-left: var(--jpo-common-base-spacing);
}

.t08fYq_mutedColor {
  color: var(--ds-text-subtlest, var(--jpo-text-muted-color));
}

.THfINa_actionGroup {
  padding-bottom: calc(var(--jpo-common-base-spacing) * 3);
}

.THfINa_headersColor {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
}

.THfINa_actionGroupHeader {
  font-weight: var(--ds-font-weight-semibold, 600);
  font-size: 12px;
}

.THfINa_actionGroupRadios {
  margin-top: calc(var(--jpo-common-base-spacing) / 2);
}

.THfINa_updatedInfoGroup {
  line-height: calc(var(--jpo-common-base-spacing) * 4);
  padding: calc(var(--jpo-common-base-spacing) * 2) 0;
  justify-content: space-between;
  display: flex;
}

.THfINa_modifiedAt {
  min-width: 40%;
}

._8w1NhG_actionGroup {
  margin-top: calc(var(--jpo-common-base-spacing) * 2);
}

._8w1NhG_actionGroupHeader {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-weight: var(--ds-font-weight-semibold, 600);
  font-size: 12px;
}

._8w1NhG_actionGroupRadios {
  margin-top: calc(var(--jpo-common-base-spacing) / 2);
}

.OuAaVW_formItemWrapper {
  margin-top: calc(var(--jpo-common-base-spacing) * 2);

  &:first-child {
    margin-top: 0;
  }
}

.OuAaVW_fieldLabel {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-size: 12px;
  font-weight: var(--ds-font-weight-semibold, 600);
  margin: 20px 0 4px;
  line-height: 16px;
}

.OuAaVW_fieldHint {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  margin-top: calc(var(--jpo-common-base-spacing) / 2);
  font-size: 12px;
}

.OuAaVW_checkboxHint {
  margin-left: calc(var(--jpo-common-base-spacing) * 4);
}

.wVb0Qa_skeleton {
  position: relative;
}

.kevL5G_error-details {
  background-color: var(--ds-background-neutral, var(--adg3-color-N40));
  white-space: pre-wrap;
  word-wrap: break-word;
  border-radius: 5px;
  padding: 10px;
}

._8mXQ2q_email-link {
  & a {
    color: var(--ds-text, var(--adg3-color-N0));
    text-decoration: underline;
  }
}

._8mXQ2q_error-log {
  max-width: 100%;
  overflow-x: auto;
}

.wxrwna_clearButton {
  font-size: 12px;
}

.icFyjG_footerActionsSeparator {
  text-align: center;
  width: 16px;
  display: inline-block;
}

._5PsE7a_spinner-container {
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 480px;
  max-height: 100%;
  display: flex;
}

._5PsE7a_description-paragraphs {
  margin-bottom: calc(var(--jpo-common-base-spacing) * 3);
}

.G8DJra_radioSelect {
  margin-top: calc(var(--jpo-common-base-spacing) * 2);
}

.G8DJra_addTeamDialogBody {
  gap: calc(var(--jpo-common-base-spacing) * 3);
  margin-top: 10px;
  display: flex;
}

.G8DJra_imageIllustrationPanel {
  text-align: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  width: 365px;
  min-width: 0;
  height: 295px;
  display: flex;

  & img {
    width: 230px;
  }
}

.G8DJra_teamSelectionPanel {
  flex: 1;
}

.G8DJra_teamField {
  margin-left: calc(var(--jpo-common-base-spacing) * 3.5);
}

.G8DJra_teamTypeOption {
  align-items: center;
  gap: var(--jpo-common-base-spacing);
  display: flex;
}

.G8DJra_teamTypeOptionIcon {
  flex: 0 0 32px;
}

.G8DJra_teamTypeOptionTitle {
  color: var(--ds-text, var(--adg3-color-N800));
  word-break: break-word;
  font-size: 14px;
}

.G8DJra_teamTypeOptionDescription {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  word-break: break-word;
  margin-top: 3px;
  font-size: 12px;
}

.kVkJFG_members-option {
  align-items: center;
  display: flex;

  & .kVkJFG_title {
    padding-left: calc(.5 * var(--jpo-common-base-spacing));
  }
}

.H73rza_planningFields {
  gap: calc(var(--jpo-common-base-spacing) * 2);
  display: flex;

  & > div {
    flex: 1;
  }
}

.H73rza_hiddenSubmitButton {
  display: none;
}

.H73rza_teamFormHeading {
  margin-bottom: var(--jpo-common-base-spacing);
  margin-top: calc(var(--jpo-common-base-spacing) * 3);
}

.H73rza_teamInfoHeadingCont {
  margin-bottom: var(--jpo-common-base-spacing);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.H73rza_planningStyleOption {
  justify-content: space-between;
  display: flex;
}

.H73rza_explainRequiredFields {
  color: var(--ds-text-subtle, #42526e);
  margin-bottom: calc(var(--jpo-common-base-spacing) * 3);
}

.weF-QW_card {
  background-color: var(--ds-surface-raised, var(--adg3-color-N0));
  box-shadow: var(--ds-shadow-raised, 0 1px 1px #091e4240, 0 0 1px 1px #091e4221);
  border-radius: 5px;
}

.weF-QW_cardHeader {
  background-position: center;
  background-size: cover;
  border-radius: 5px 5px 0 0;
  height: 80px;
}

.weF-QW_cardContent {
  padding: calc(var(--jpo-common-base-spacing) * 3);
  align-items: flex-end;
  display: flex;
}

.weF-QW_heading {
  font-size: 20px;
  font-weight: var(--ds-font-weight-medium, 500);
}

.weF-QW_subheading {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-weight: var(--ds-font-weight-regular, 400);
  margin: calc(var(--jpo-common-base-spacing) / 2) 0 calc(var(--jpo-common-base-spacing) * 2) 0;
  align-items: center;
  gap: 5px;
  display: flex;
}

.weF-QW_teamInfo {
  flex: 1;
}

.weF-QW_button {
  flex-shrink: 0;
  margin-top: 24px;
}

.weF-QW_loadingOrError {
  justify-content: center;
  align-items: center;
  height: 200px;
  display: flex;
}

.weF-QW_errorContainer {
  text-align: center;
  margin-bottom: calc(var(--jpo-common-base-spacing) * 2);
}

.D7tXFa_dialogWrapper {
  flex: 1;
  align-items: center;
  height: 300px;
  display: flex;
}

.D7tXFa_imageIllustrationPanel {
  flex: 1;
  justify-content: center;
  margin-top: 40px;
  display: flex;

  & img {
    width: 215px;
  }
}

.D7tXFa_messagePanel {
  flex: 1;
  margin-top: 40px;
}

.D7tXFa_prompt {
  color: var(--ds-text-subtle, var(--jpo-text-secondary-color));
}

.VqplxG_sections {
  flex: 1 0 auto;
  height: 100%;
  display: flex;
}

.VqplxG_section {
  flex: none;
  height: 0;
  min-height: 100%;
  display: flex;
  overflow: hidden;
}

.VqplxG_separator {
  cursor: ew-resize;
  user-select: none;
  z-index: 2;
  flex: none;
  width: 10px;
  height: 100%;
  margin-right: -10px;
  position: relative;
  left: -5px;
  overflow: visible;

  & > .VqplxG_handle {
    border-right: thin solid var(--ds-border, var(--adg3-color-N50));
    width: 0;
    height: 100%;
    margin-left: 4px;
  }

  & > .VqplxG_handle.VqplxG_drag-active, &:hover > .VqplxG_handle {
    border: thin solid var(--ds-border-brand, var(--adg3-color-B100));
    border-width: 0 thin;
  }
}

.VqplxG_story-title {
  font-family: sans-serif;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 10px;
}

.VqplxG_section.VqplxG_collapsedSection {
  cursor: pointer;
}

.VqplxG_title {
  align-items: center;
  display: flex;

  &.VqplxG___header {
    justify-content: center;
  }

  & .VqplxG_truncate-text {
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
  }

  & .VqplxG_title-icon {
    align-items: center;
    margin-left: 4px;
    display: flex;

    &.VqplxG___collapsed {
      margin: 6px 0 0;
    }

    &.VqplxG___collapsed > span {
      padding: 0;

      & > span {
        padding: 4px 3px;
      }
    }
  }
}

.VqplxG_columnTexts {
  pointer-events: none;
  display: flex;
}

.VqplxG_columnText {
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  display: inline-flex;
}

.VqplxG_columnTextTitle {
  writing-mode: vertical-rl;
  text-transform: uppercase;
  font-family: sans-serif;
  font-size: 10px;
  font-weight: var(--ds-font-weight-bold, bold);
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  background-color: #0000;
  align-items: center;
  margin-top: 16px;
  padding: 0 3px 0 0;
  display: flex;
}

.v2i7oq_afm-custom-popup-container {
  box-sizing: border-box;
  background-color: var(--ds-surface-overlay, var(--adg3-color-N0));
  box-shadow: var(--ds-shadow-overlay, 0 4px 8px -2px var(--adg3-color-N50A), 0 0 1px var(--adg3-color-N60A));
  border-radius: 3px;
  flex: auto;
  display: block;
  overflow: visible;

  &:focus {
    outline: none;
  }
}

.GgeTjW_afm-dropdown-menu-body {
  max-height: calc(55vh - 180px);
  margin-bottom: var(--jpo-common-base-spacing);
  overflow-y: auto;
}

.GgeTjW_afm-dropdown-menu-body .GgeTjW_afm-no-selected-fields {
  padding: calc(var(--jpo-common-base-spacing) / 2) calc(2 * var(--jpo-common-base-spacing));
}

.GgeTjW_afm-custom-field-lozenge {
  text-align: right;
}

.GgeTjW_afm-selected-field-item-old {
  cursor: move;
  background-color: var(--ds-background-neutral-subtle, var(--adg3-color-N0));
  height: 40px;
  padding: 0 calc(2 * var(--jpo-common-base-spacing));
  justify-content: space-between;
  align-items: center;
  gap: 0 calc(.5 * var(--jpo-common-base-spacing));
  display: flex;
}

.GgeTjW_afm-selected-field-item {
  cursor: move;
  background-color: var(--ds-background-neutral-subtle, var(--adg3-color-N0));
  height: 40px;
  padding: 0 calc(1.5 * var(--jpo-common-base-spacing));
  justify-content: space-between;
  align-items: center;
  gap: 0 calc(.5 * var(--jpo-common-base-spacing));
  display: flex;
}

.GgeTjW_afm-selected-field-item-section {
  align-items: center;
  gap: 0 calc(.5 * var(--jpo-common-base-spacing));
  display: flex;
}

.GgeTjW_afm-selected-field-item:hover {
  background: var(--ds-background-neutral-subtle-hovered, var(--adg3-color-N20));
}

.GgeTjW_afm-sortable-helper {
  z-index: 1200;
  background: var(--ds-background-selected, var(--adg3-color-B50));
}

.GgeTjW_afm-selected-field-item-drag-icon {
  flex-shrink: 0;
  display: flex;
}

.GgeTjW_afm-selected-field-item-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 2;
  overflow: hidden;
}

.GgeTjW_afm-selected-field-item-custom-lozenge {
  margin-bottom: 3px;
  margin-left: 3px;
}

.GgeTjW_afm-selected-field-item-remove-icon {
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
}

.GgeTjW_afm-selected-field-item-remove-icon-button {
  display: flex;
}

._2rlvoW_afm-header-left {
  cursor: default;
  margin: calc(var(--jpo-common-base-spacing) / 2);
  margin-left: var(--jpo-common-base-spacing);
  align-items: center;
  display: flex;
}

._2rlvoW_afm-header-left-old {
  cursor: default;
  margin-left: var(--jpo-common-base-spacing);
  align-items: center;
  display: flex;
}

._2rlvoW_afm-header-left div[data-role="droplistContent"] {
  max-height: 55vh;
  padding: 0;
  overflow: visible;
}

._2rlvoW_afm-header-left div[data-role="droplistContent"] > div > div {
  max-width: none;
}

._2rlvoW_afm-trigger-button {
  align-items: center;
  display: flex;
}

._2rlvoW_afm-trigger-button-text {
  flex: 1 0 auto;
}

._2rlvoW_afm-trigger-button-icon {
  flex-shrink: 1;
  align-items: center;
  display: flex;
}

._2rlvoW_afm-dropdown-container-old {
  width: 400px;
}

._2rlvoW_afm-dropdown-container {
  gap: var(--jpo-common-base-spacing);
  width: 400px;
  margin: var(--jpo-common-base-spacing) 0;
  flex-direction: column;
  display: flex;
}

._2rlvoW_afm-dropdown-menu-header-old {
  padding: calc(2 * var(--jpo-common-base-spacing));
  border-bottom: 1px solid var(--ds-border, var(--adg3-color-N40));
}

._2rlvoW_afm-dropdown-menu-header {
  padding: var(--jpo-common-base-spacing) calc(2 * var(--jpo-common-base-spacing));
}

._2rlvoW_afm-dropdown-menu-body {
  max-height: calc(55vh - 180px);
  padding: calc(2 * var(--jpo-common-base-spacing));
  background-color: var(--ds-background-neutral, var(--adg3-color-N20));
  border-bottom: 1px solid var(--ds-border, var(--adg3-color-N40));
  overflow-y: auto;
}

._2rlvoW_afm-dropdown-menu-footer {
  padding: 6px 0;
}

._2rlvoW_afm-dropdown-menu-divider {
  height: 1px;
  margin: 0 calc(2 * var(--jpo-common-base-spacing));
  background: var(--ds-border, var(--adg3-color-N40));
}

._2rlvoW_afm-dropdown-menu-footer-old {
  padding: calc(2 * var(--jpo-common-base-spacing));
}

._2rlvoW_afm-sortable-helper {
  z-index: 1200;
}

._2rlvoW_afm-select-option-label {
  display: flex;
}

._2rlvoW_afm-custom-field-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 2;
  overflow: hidden;
}

._2rlvoW_afm-custom-field-lozenge {
  text-align: right;
  flex-grow: 1;
}

._2rlvoW_afm-selected-field-item {
  cursor: move;
  background-color: var(--ds-surface, var(--adg3-color-N0));
  height: 38px;
  box-shadow: var(--ds-shadow-raised, 0 0 1px 0 #091e424f), var(--ds-shadow-raised, 0 2px 4px -1px #091e4240);
  border-radius: 2px;
  align-items: center;
  margin-bottom: 3px;
  display: flex;
}

._2rlvoW_afm-selected-field-item:hover {
  background: var(--ds-background-selected, var(--adg3-color-B50));
}

._2rlvoW_afm-selected-field-item-drag-icon {
  flex-shrink: 0;
  margin-top: 2px;
}

._2rlvoW_afm-selected-field-item-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 2;
  overflow: hidden;
}

._2rlvoW_afm-selected-field-item-custom-lozenge {
  margin-bottom: 3px;
  margin-left: 3px;
}

._2rlvoW_afm-selected-field-item-remove-icon {
  cursor: pointer;
  flex-shrink: 0;
  margin: 2px 4px 0;
}

._2rlvoW_afm-field-option {
  align-items: center;
  width: 100%;
  display: flex;
}

._2rlvoW_afm-field-option-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 2;
  overflow: hidden;
}

._2rlvoW_afm-field-option-custom-lozenge {
  margin-left: 3px;
  line-height: 1;
}

.Rrwtxa_nowLineStaticAxisWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
}

.Rrwtxa_nowLineStaticAxisSubWrapper {
  justify-content: center;
  display: flex;
}

.Rrwtxa_nowLine {
  z-index: 4;
  pointer-events: none;
  border-left: 2px solid var(--ds-background-accent-orange-subtle, var(--adg3-color-Y400));
  width: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
}

.Rrwtxa_marker:before {
  content: "";
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  border-top: 8px solid var(--ds-background-accent-orange-subtle, var(--adg3-color-Y400));
  width: 0;
  height: 0;
  margin-left: -5px;
  position: sticky;
  top: 0;
}

.ztFJyq_overlay {
  position: absolute;
  inset: 0;
}

.ztFJyq_anchor {
  position: absolute;
}

._8h9bCa_isReadOnly > div > div > div > div {
  border-color: #0000 !important;
}

.OMlRpG_container {
  color: var(--ds-text-disabled, var(--adg3-color-N70));
  height: 40px;
  padding-left: calc(var(--jpo-common-base-spacing) * 1.75);
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.lBMG-a_textFieldWrapper [data-ds--text-field--input] {
  text-overflow: ellipsis;
}

.lBMG-a_textFieldWrapper.lBMG-a_readOnly [data-ds--text-field--input] {
  color: var(--ds-text-disabled, var(--adg3-color-N70));
  cursor: not-allowed;
  pointer-events: auto;
  text-overflow: ellipsis;
}

.Z1ySdG_readOnly input {
  color: var(--ds-text-disabled, var(--adg3-color-N70));
}

.GgqE6a_textFieldWrapper [data-ds--text-field--input] {
  text-overflow: ellipsis;
}

.GgqE6a_textFieldWrapper.GgqE6a_readOnly [data-ds--text-field--input] {
  color: var(--ds-text-disabled, var(--adg3-color-N70));
  cursor: not-allowed;
  pointer-events: auto;
  text-overflow: ellipsis;
}

.WjFy1q_container {
  white-space: nowrap;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  display: flex;

  & a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.HckXpG_wireframeWrapper {
  display: flex;
}

.HckXpG_wireframeTitle {
  padding-left: var(--jpo-spacing);
  display: inline-block;
}

.-_Hfnq_stickHeaderLayout {
  z-index: 1;
  box-sizing: border-box;
  background: none;
  height: 100%;
}

.-_Hfnq_columnHeaderWrapper {
  background: var(--ds-surface, var(--jpo-bg-default-color));
  border-bottom: 1px solid var(--ds-border, var(--jpo-border-secondary-color));
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
}

.-_Hfnq_columnHeaderSection {
  height: 100%;
}

.-_Hfnq_portalContainer {
  z-index: 3;
  width: 100%;
  height: 100%;
}

.-_Hfnq_fieldColumnHeaderWrapper {
  width: calc(100% - 2px);
  margin: 0 1px;
}

.-_Hfnq_scopeColumnHeaderWrapper {
  width: calc(100% - 1px);
  margin: 0 1px 0 0;
}

.hgfPNG_container {
  height: inherit;
  justify-content: center;
  display: flex;
  position: fixed;
  overflow: hidden;
}

.hgfPNG_imageGroup {
  text-align: center;
  min-width: 280px;
  max-width: 360px;
  padding: calc(var(--jpo-common-base-spacing) * 5) calc(var(--jpo-common-base-spacing) * 3) 0;
}

.hgfPNG_image {
  min-width: 100%;
  max-width: 100%;
  height: auto;
}

.hgfPNG_header {
  margin-top: var(--jpo-common-base-spacing);
}

.hgfPNG_text {
  padding: 0 calc(var(--jpo-common-base-spacing) * 2);
}

.hgfPNG_dropdown {
  margin: calc(var(--jpo-common-base-spacing) * 2) 0;
  justify-content: center;
  display: flex;
}

.lZPKBG_menu-item.lZPKBG_selected:hover, .lZPKBG_menu-item.lZPKBG_selected {
  color: var(--ds-text-inverse, var(--adg3-color-N20));
  background-color: var(--ds-background-neutral-bold-hovered, var(--adg3-color-N700));
  border-radius: 0;
}

.SOS43G_inline-dialog-child {
  min-width: 250px;
}

.SOS43G_option {
  display: flex;
}

.SOS43G_avatar {
  margin-right: var(--jpo-common-base-spacing);
  display: inline-flex;
}

.SOS43G_label {
  align-items: center;
  display: inline-flex;
}

.SOS43G_no-matches {
  padding: var(--jpo-common-base-spacing) calc(2 * var(--jpo-common-base-spacing));
  color: var(--ds-text-disabled, var(--adg3-color-N200));
  text-align: center;
}

.SOS43G_spinner {
  min-width: 200px;
  margin: calc(2 * var(--jpo-common-base-spacing));
  text-align: center;
}

.pqcCAG_inline-dialog-child {
  min-width: 250px;
}

.pqcCAG_no-matches {
  padding: var(--jpo-common-base-spacing) calc(2 * var(--jpo-common-base-spacing));
  color: var(--ds-text-disabled, var(--adg3-color-N200));
  text-align: center;
}

.cuQn0G_inline-dialog-child {
  min-width: 250px;
}

.cuQn0G_no-matches {
  padding: var(--jpo-common-base-spacing) calc(2 * var(--jpo-common-base-spacing));
  color: var(--ds-text-disabled, var(--adg3-color-N200));
  text-align: center;
}

.OU0D_a_form-group {
  display: flex;
}

.OU0D_a_current-value {
  padding-top: calc(var(--jpo-common-base-spacing)  + 2px);
}

.OU0D_a_current-value-title {
  max-width: calc(var(--jpo-common-base-spacing) * 24);
  text-overflow: ellipsis;
  overflow: hidden;
}

.OU0D_a_icon {
  vertical-align: -5px;
  padding-left: calc(var(--jpo-common-base-spacing) * 2);
  text-align: center;
  width: 80px;
  margin-top: 36px;
}

.OU0D_a_new-value {
  min-width: 280px;
  margin-left: calc(var(--jpo-common-base-spacing) * 2);
}

.OU0D_a_value-label {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-size: 12px;
  font-weight: var(--ds-font-weight-semibold, 600);
}

.OU0D_a_value-placeholder {
  padding-top: calc(var(--jpo-common-base-spacing) / 2);
  text-overflow: ellipsis;
  box-sizing: border-box;
  white-space: nowrap;
  align-items: center;
  min-width: 136px;
  max-width: 216px;
  line-height: 40px;
  display: flex;
  overflow: hidden;
}

.OU0D_a_should-fit-full-width .OU0D_a_value-placeholder {
  max-width: 220px;
}

.OU0D_a_should-fit-full-width .OU0D_a_new-value {
  flex: 1 0 auto;
}

.OU0D_a_form-footer {
  justify-content: flex-end;
  padding: 16px 0 20px;
  display: flex;

  & button {
    margin: 0 4px;
  }
}

.OU0D_a_issue-type-icon {
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-right: var(--jpo-common-base-spacing);
  display: inline-block;
}

.OU0D_a_avatar {
  margin-right: var(--jpo-common-base-spacing);
  align-items: center;
  display: flex;
}

._3aDJ6G_inline-dialog-container {
  max-width: 100%;
  display: inline-block;
}

._3aDJ6G_issue-option-wrapper {
  padding: var(--jpo-common-base-spacing) 0;
  min-width: auto;
  display: flex;
}

._3aDJ6G_issue-type-icon {
  vertical-align: middle;
  width: calc(2 * var(--jpo-common-base-spacing));
  min-width: calc(2 * var(--jpo-common-base-spacing));
  height: calc(2 * var(--jpo-common-base-spacing));
  margin-right: var(--jpo-common-base-spacing);
  margin-top: 2px;
  display: inline-block;
}

._3aDJ6G_issue-label {
  box-sizing: border-box;
  display: block;
}

._3aDJ6G_link {
  white-space: nowrap;
  margin-right: var(--jpo-common-base-spacing);
  display: flex;
}

.seqU5a_content {
  margin: var(--jpo-common-base-spacing) 0;
  display: flex;
}

.seqU5a_startDate {
  border-right: 1px solid var(--ds-border, var(--adg3-color-N50));
  padding-right: calc(2 * var(--jpo-common-base-spacing));
  flex: 1;
}

.seqU5a_endDate {
  margin-left: calc(2 * var(--jpo-common-base-spacing));
  flex: 1;
}

.seqU5a_dateTitle {
  font-size: 12px;
  font-weight: var(--ds-font-weight-semibold, 600);
  color: var(--ds-text, var(--adg3-color-N800));
  text-transform: uppercase;
  line-height: calc(2 * var(--jpo-common-base-spacing));
  margin-top: var(--jpo-common-base-spacing);
  margin-bottom: var(--jpo-common-base-spacing);
}

.seqU5a_values {
  margin-bottom: var(--jpo-common-base-spacing);
  display: flex;
}

.seqU5a_currentValue {
  flex: 1.75;
}

.seqU5a_valueTitle {
  font-size: 11px;
  font-weight: var(--ds-font-weight-bold, bold);
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  margin-bottom: var(--jpo-common-base-spacing);
  display: flex;
}

.seqU5a_title {
  flex: 1;
  padding-top: 4px;
}

.seqU5a_newValue {
  margin-left: var(--jpo-common-base-spacing);
  flex: 3;
}

.seqU5a_arrow {
  text-align: right;
  flex: 1;
}

.seqU5a_newValueForm > div:first-child {
  margin-left: -11px;
}

.YXOtZW_form-group {
  display: flex;
}

.YXOtZW_current-value {
  padding-top: calc(var(--jpo-common-base-spacing)  + 2px);
}

.YXOtZW_current-value-title {
  max-width: calc(var(--jpo-common-base-spacing) * 24);
  text-overflow: ellipsis;
  overflow: hidden;
}

.YXOtZW_icon {
  vertical-align: -5px;
  padding-left: calc(var(--jpo-common-base-spacing) * 2);
  text-align: center;
  width: 80px;
  margin-top: 36px;
}

.YXOtZW_checkbox {
  margin-top: var(--jpo-common-base-spacing);
}

.YXOtZW_new-value {
  min-width: 280px;
  margin-left: calc(var(--jpo-common-base-spacing) * 2);
}

.YXOtZW_value-label {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-size: 12px;
  font-weight: var(--ds-font-weight-semibold, 600);
}

.YXOtZW_value-placeholder {
  padding-top: calc(var(--jpo-common-base-spacing) / 2);
  text-overflow: ellipsis;
  box-sizing: border-box;
  white-space: nowrap;
  align-items: center;
  min-width: 136px;
  max-width: 216px;
  line-height: 40px;
  display: flex;
  overflow: hidden;
}

.gE_UFq_field-action-container {
  padding-bottom: 16px;
}

._8sj1zG_form-group {
  display: flex;
}

._8sj1zG_current-value-title {
  max-width: calc(var(--jpo-common-base-spacing) * 24);
  text-overflow: ellipsis;
  overflow: hidden;
}

._8sj1zG_icon {
  padding-left: calc(var(--jpo-common-base-spacing) * 2);
  text-align: center;
  width: 80px;
  margin-top: 36px;
}

._8sj1zG_new-value {
  width: 280px;
  margin-left: calc(var(--jpo-common-base-spacing) * 2);
}

._8sj1zG_value-label {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-size: 12px;
  font-weight: var(--ds-font-weight-semibold, 600);
  margin-top: 8px;
  margin-bottom: 4px;
}

._8sj1zG_value-placeholder {
  width: 136px;
  padding-top: 8px;
}

._8sj1zG_current-container {
  flex-wrap: wrap;
  display: flex;
}

.HoAo0W_section-message {
  margin: calc(2.5 * var(--jpo-common-base-spacing)) 0;
}

.HoAo0W_inline-dialog-container {
  max-width: 100%;
  display: inline-block;
}

.HoAo0W_close-icon {
  cursor: pointer;
  top: calc(-1.5 * var(--jpo-common-base-spacing));
  right: calc(-2.5 * var(--jpo-common-base-spacing));
  position: absolute;
}

.HoAo0W_issue-option-wrapper {
  padding: var(--jpo-common-base-spacing) 0;
  min-width: auto;
  display: flex;
}

.HoAo0W_issue-type-icon {
  vertical-align: middle;
  width: calc(2 * var(--jpo-common-base-spacing));
  min-width: calc(2 * var(--jpo-common-base-spacing));
  height: calc(2 * var(--jpo-common-base-spacing));
  margin-right: var(--jpo-common-base-spacing);
  margin-top: 2px;
  display: inline-block;
}

.HoAo0W_issue-label {
  box-sizing: border-box;
  display: block;
}

.HoAo0W_link {
  white-space: nowrap;
  margin-right: var(--jpo-common-base-spacing);
  display: flex;
}

._1B3yza_form-group {
  display: flex;
}

._1B3yza_current-value {
  padding-top: calc(var(--jpo-common-base-spacing)  + 2px);
}

._1B3yza_icon {
  vertical-align: -5px;
  padding: 0 calc(var(--jpo-common-base-spacing) * 2);
  margin-top: 40px;
}

._1B3yza_value-label {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-size: 12px;
  font-weight: var(--ds-font-weight-semibold, 600);
}

._1B3yza_value-placeholder {
  padding-top: calc(var(--jpo-common-base-spacing) / 2);
  text-overflow: ellipsis;
  box-sizing: border-box;
  white-space: nowrap;
  max-width: 260px;
  line-height: 40px;
  overflow: hidden;
}

._1B3yza_operation-type {
  flex-basis: 30%;
}

._1B3yza_anchor {
  flex-basis: 50%;
  padding: 23px 0 0 8px;
  display: block;
}

._1B3yza_form-footer {
  justify-content: flex-end;
  padding: 16px 0 20px;
  display: flex;

  & button {
    margin: 0 4px;
  }
}

.xGwiYW_barFlyout {
  display: inline-block;

  & tbody {
    border: none;
  }

  & table td {
    max-width: 150px;
    padding: 3px 5px;
  }

  & table tr {
    vertical-align: text-bottom;
    line-height: 20px;
  }

  & table td:first-child {
    min-width: 70px;
  }

  & table td:nth-child(4) {
    max-width: 250px;
  }

  & .xGwiYW_label {
    color: var(--ds-text-subtlest, var(--adg3-color-N200));
    font-size: 12px;
    font-weight: var(--ds-font-weight-semibold, 600);
    line-height: 16px;
  }

  & .xGwiYW_none, & .xGwiYW_delta {
    color: var(--ds-text-subtlest, var(--adg3-color-N200));
  }

  & .xGwiYW_valueWithIcon {
    align-items: baseline;
    display: flex;
  }

  & .xGwiYW_rollUpIcon {
    position: relative;
    top: 5px;
  }

  & .xGwiYW_sprintIcon {
    padding-right: 6px;
  }

  & .xGwiYW_inferredText {
    color: var(--ds-text-subtlest, var(--adg3-color-N200));
    font-style: italic;
  }
}

._0kirEG_issueTypeLabel {
  max-width: 215px;
  display: inline-block;
}

.wrZGAG_root {
  margin-right: 6px;
}

.wrZGAG_moreIconWrapper {
  width: calc(var(--jpo-icon-size-small)  + 8px);
  align-items: center;
  margin: 0;
  padding: 2px 3px;
  display: flex;
}

.wrZGAG_divider {
  background-color: var(--ds-border, var(--adg3-color-N30));
  height: 2px;
  margin-top: calc(var(--jpo-common-base-spacing) / 2);
  margin-bottom: calc(var(--jpo-common-base-spacing) / 2);
}

._5eHewW_textMatch {
  background-color: var(--ds-background-accent-purple-subtle, #8777d9b3);
  color: var(--ds-text-inverse, var(--adg3-color-N0));
  border-radius: 2px;
}

._5eHewW_textMatch._5eHewW_isActive {
  background-color: var(--ds-background-accent-purple-bolder, var(--adg3-color-P300));
}

._5eHewW_ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

:root {
  --ranked-gutter-size: 5px;
}

.ra40ka_container {
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  height: 40px;
  padding: 12px 0;
  display: flex;
}

.ra40ka_rank {
  color: var(--ds-text-subtlest, var(--adg3-color-subtleText-light));
  padding: 0;
}

.ra40ka_ranked-gutter {
  min-width: var(--ranked-gutter-size);
  max-width: var(--ranked-gutter-size);
  width: var(--ranked-gutter-size);
  height: 40px;
  margin-right: calc(var(--jpo-common-base-spacing)  - var(--ranked-gutter-size));
}

.ra40ka_icon {
  width: var(--jpo-icon-size-medium);
  min-width: var(--jpo-icon-size-medium);
  height: var(--jpo-icon-size-medium);
  cursor: pointer;
  padding: 0;
}

.ra40ka_spacer {
  height: var(--jpo-icon-size-medium);
  cursor: pointer;
  padding: 0;
}

.ra40ka_select-checkbox {
  min-width: var(--jpo-icon-size-large);
  user-select: none;
}

.ra40ka_issue-icon-old {
  width: var(--jpo-icon-size-small);
  min-width: var(--jpo-icon-size-small);
  height: var(--jpo-icon-size-small);
  margin-right: 5px;
}

.ra40ka_issue-icon {
  width: var(--jpo-icon-size-small);
  min-width: var(--jpo-icon-size-small);
  height: var(--jpo-icon-size-small);
  pointer-events: none;
  margin-right: 5px;
}

.ra40ka_issue-icon-new {
  width: var(--jpo-icon-size-small);
  min-width: var(--jpo-icon-size-small);
  height: var(--jpo-icon-size-small);
  margin-right: 5px;
  display: block;
}

.ra40ka_issueIconTooltipWrapper {
  align-self: baseline;
  display: flex;
}

.ra40ka_container:hover .ra40ka_addIcon, .ra40ka_container [aria-expanded="true"] .ra40ka_addIcon {
  display: flex;
}

.ra40ka_toggle {
  background-color: #0000;
  border: none;
  outline: none;
}

.ra40ka_link {
  white-space: nowrap;
  margin-right: 5px;
  display: flex;
}

.ra40ka_parent-match {
  background-color: var(--ds-background-accent-purple-subtle, #8777d980);
  border-radius: 2px;
  width: 14px;
  min-width: 14px;
  margin: 0 5px;

  & > span {
    margin-left: -5px;
  }
}

.ra40ka_summary {
  flex: 1 1 0;
  min-width: 0;

  & > form > div {
    margin-top: 0;
    margin-left: 0;
  }

  & input {
    cursor: initial;
  }
}

.ra40ka_summaryReadView {
  min-height: 1.42857em;
  padding: var(--jpo-common-base-spacing) 6px;
  line-height: 1.42857;
  display: flex;
}

.ra40ka_ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ra40ka_light-summary {
  pointer-events: none;
  padding-left: var(--jpo-common-base-spacing);
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1 0;
  align-items: center;
  min-width: 0;
  padding-right: 21px;
  display: flex;
  overflow: hidden;
}

.ra40ka_summaryBtn {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.ra40ka_odd {
  background-color: var(--ds-background-accent-gray-subtlest, var(--adg3-color-N20));
}

.ra40ka_oddOptimized {
  background-color: var(--ds-background-accent-gray-subtlest, var(--adg3-color-N20-transparent));
}

.ra40ka_even {
  background-color: var(--ds-surface, var(--adg3-color-N0));
}

.ra40ka_evenOptimized {
  background-color: #0000;
}

.ra40ka_warnings {
  background-color: var(--ds-background-warning, var(--adg3-color-Y50));
}

.ra40ka_warningsOptimized {
  background-color: var(--ds-background-warning-hovered, color(var(--adg3-color-Y75) a(33%)));
}

.ra40ka_isDragging, .ra40ka_isSelected {
  background-color: var(--ds-background-selected, var(--adg3-color-B50));
}

.ra40ka_drop-parent {
  background-color: var(--ds-background-selected, var(--adg3-color-B50));
  border-color: var(--ds-border-focused, var(--adg3-color-B200));
  border-style: solid;
  border-width: 2px 3px 2px 2px;
}

.ra40ka_drop-parent .ra40ka_ranked-gutter {
  height: 36px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.ra40ka_flyoutPositioner {
  position: absolute;
}

.ra40ka_group-indentation {
  min-width: calc(var(--jpo-common-base-spacing) * 5);
}

.Wh34OW_cellButton:hover {
  background-color: #0000 !important;
}

.iawNsq_container {
  box-sizing: border-box;
  align-items: center;
  height: 40px;
  padding: 12px 0 12px 20px;
  display: flex;
}

.iawNsq_containerOld {
  box-sizing: border-box;
  background-color: var(--ds-surface, var(--adg3-color-N0));
  align-items: center;
  height: 40px;
  padding: 12px 0 12px 20px;
  display: flex;
}

.iawNsq_rank {
  color: var(--ds-text-subtlest, var(--adg3-color-subtleText-light));
  padding: 0;
}

.iawNsq_icon {
  width: var(--jpo-icon-size-medium);
  min-width: var(--jpo-icon-size-medium);
  height: var(--jpo-icon-size-medium);
  cursor: pointer;
  padding: 0;
}

.iawNsq_summary {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-width: 0;
  overflow: hidden;
}

.iawNsq_toggleButton {
  cursor: pointer;
  outline: none;
}

.wnXJHq_wrapper {
  background-color: var(--ds-background-neutral, var(--adg3-color-N40));
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.wnXJHq_initials {
  font-weight: var(--ds-font-weight-semibold, 600);
  color: var(--ds-text, var(--adg3-color-N800));
}

.dMdMgW_tooltip-wrapper {
  padding: var(--jpo-common-base-spacing);
  text-align: center;
  color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.dMdMgW_tooltip-title {
  font-size: 14px;
}

.dMdMgW_external-lozenge-wrapper {
  margin: 0 var(--jpo-common-base-spacing);
}

.dMdMgW_group-details {
  background-color: var(--ds-surface, var(--jpo-bg-default-color));
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 39px;
  box-shadow: 0px -2px var(--ds-border, var(--adg3-color-N40));
  cursor: pointer;
  outline: none;
  flex-direction: row;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.dMdMgW_group-details-transposed {
  background-color: var(--ds-surface, var(--jpo-bg-default-color));
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  outline: none;
  flex-direction: row;
  align-items: center;
  height: 39px;
  display: flex;
  overflow: hidden;
}

.dMdMgW_group-details:focus {
  box-shadow: inset 0 0 0 2px var(--ds-border-focused, #4c9aff);
  outline-offset: 0;
}

.dMdMgW_group-chevron {
  padding-left: 8px;

  &.dMdMgW_hasParentGroup {
    margin-left: 40px;
  }
}

.dMdMgW_groupingAvatar {
  min-width: 24px;
  padding-left: 8px;
  display: inline-block;
}

.dMdMgW_defaultTeamAvatar {
  background-color: var(--ds-background-neutral, var(--adg3-color-N40));
  border-radius: 50%;
  margin: 0 2px;
  padding: 4px;

  & > span {
    display: flex;
  }
}

.dMdMgW_group-title {
  font-size: 16px;
  font-weight: var(--ds-font-weight-medium, 500);
  color: var(--ds-text, var(--adg3-color-N800));
  flex-grow: 1;
  align-items: center;
  padding-left: 9px;
  display: flex;
  overflow: hidden;
}

.dMdMgW_group-title-old {
  font-size: 16px;
  font-weight: var(--ds-font-weight-medium, 500);
  color: var(--ds-text, var(--adg3-color-N800));
  flex-grow: 1;
  padding-left: 9px;
  overflow: hidden;
}

.dMdMgW_tooltip {
  padding: calc(var(--jpo-common-base-spacing) * .5) 0;
  text-align: center;
}

.dMdMgW_tooltip > h6 {
  font-weight: var(--ds-font-weight-bold, bold);
  color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.dMdMgW_groupName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dMdMgW_groupRow {
  background-color: var(--ds-surface, var(--jpo-bg-default-color));
}

.dMdMgW_exportPNGLozenge {
  margin: 0 var(--jpo-common-base-spacing);
  align-items: center;
  display: flex;
}

.SM_IKW_emptyView {
  text-align: center;
  height: 100%;
  margin: auto;
  padding: 50px 10px 0;
  overflow: hidden;
}

.jn3g7W_emptyView {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
  margin: auto;
  padding-top: 50px;
  overflow: hidden;
}

.jn3g7W_line2 {
  padding-top: 20px;
}

div.BVYcca_isDragging {
  background-color: var(--ds-background-selected, var(--adg3-color-B50));
  border-color: var(--ds-border, var(--adg3-color-N60A));
  box-shadow: 0 4px 8px -2px var(--ds-shadow-overlay, var(--adg3-color-N50A));
  z-index: 800;
  border-width: 0 0 1px;
}

div.BVYcca_isDragging.BVYcca_isInvalid {
  background-color: var(--ds-background-danger, var(--adg3-color-R50));
}

.BVYcca_drop-sibling {
  background-color: var(--ds-background-selected-hovered, var(--adg3-color-B75));
}

.BVYcca_drop-invalid-sibling {
  background-color: var(--ds-background-danger-hovered, var(--adg3-color-R75));
}

.BVYcca_drop-container {
  transition-property: background;
  transition-duration: .5s;
}

.BVYcca_drop-child-odd {
  background: linear-gradient(.25turn, var(--ds-background-neutral-hovered, var(--adg3-color-N30)), var(--ds-background-neutral-hovered, var(--adg3-color-N30)) 16px, var(--ds-background-selected-hovered, var(--adg3-color-B75)) 16px, var(--ds-background-selected-hovered, var(--adg3-color-B75)));
}

.BVYcca_drop-child-even {
  background: linear-gradient(.25turn, var(--ds-background-inverse-subtle, var(--adg3-color-N0)), var(--ds-background-inverse-subtle, var(--adg3-color-N0)) 16px, var(--ds-background-selected-hovered, var(--adg3-color-B75)) 16px, var(--ds-background-selected-hovered, var(--adg3-color-B75)));
}

.BVYcca_drop-invalid-child-odd {
  background: linear-gradient(.25turn, var(--ds-background-neutral-hovered, var(--adg3-color-N30)), var(--ds-background-neutral-hovered, var(--adg3-color-N30)) 16px, var(--ds-background-danger-hovered, var(--adg3-color-R75)) 16px, var(--ds-background-danger-hovered, var(--adg3-color-R75)));
}

.BVYcca_drop-invalid-child-even {
  background: linear-gradient(.25turn, var(--ds-background-inverse-subtle, var(--adg3-color-N0)), var(--ds-background-inverse-subtle, var(--adg3-color-N0)) 16px, var(--ds-background-danger-hovered, var(--adg3-color-R75)) 16px, var(--ds-background-danger-hovered, var(--adg3-color-R75)));
}

.BVYcca_unselected {
  user-select: none;
}

.BVYcca_blankRow {
  background-color: var(--ds-surface, var(--adg3-color-N0));
  height: 100%;
}

.BVYcca_group-team-avatar {
  width: 24px;
  display: inline-block;
}

.BVYcca_row-with-indentation {
  background-color: var(--ds-surface, var(--adg3-color-N0));
  padding-left: 40px;
}

.CzkEIW_dropdown {
  margin-right: 10px;
}

.CzkEIW_icon {
  width: var(--jpo-icon-size-small);
  min-width: var(--jpo-icon-size-small);
  height: var(--jpo-icon-size-small);
  margin: var(--jpo-common-base-spacing) 0;
}

.CzkEIW_assertiveIcon {
  margin-right: 20px;
}

.CzkEIW_projectIcon {
  background-position: 0;
  background-repeat: no-repeat;
  background-size: var(--jpo-icon-size-small);
  padding-left: 25px;
}

.CzkEIW_inlineEdit {
  padding-right: var(--jpo-common-base-spacing);
  width: 100%;
  min-width: 0;

  & > form > div {
    margin-top: 0;
  }

  & input {
    cursor: initial;
  }
}

.CzkEIW_validationText {
  display: flex;
}

.CzkEIW_validationIcon {
  margin-top: calc(var(--jpo-common-base-spacing) * -1 / 2);
  margin-right: calc(var(--jpo-common-base-spacing) / 2);
}

.CzkEIW_validationTextMessage {
  flex: auto;
}

.CzkEIW_container {
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.CzkEIW_spinner {
  padding: var(--jpo-common-base-spacing);
  margin-right: var(--jpo-common-base-spacing);
}

.dAFjAq_issue-icon {
  width: var(--jpo-icon-size-small);
  height: var(--jpo-icon-size-small);
  background-size: contain;
  margin-right: 5px;
}

.dAFjAq_breadCrumbItem {
  margin: 0;
  display: flex;

  &:after {
    content: "/";
    padding: 0px var(--jpo-spacing);
    width: var(--jpo-spacing);
    flex-shrink: 0;
    justify-content: center;
    margin-top: 2px;
    margin-right: -4px;
    display: flex;
  }
}

.-\38 IA7a_modalHeader {
  padding: calc(var(--jpo-spacing) * 2) calc(var(--jpo-spacing) * 3);
  align-items: center;
  display: flex;
  position: relative;
}

.-\38 IA7a_breadcrumbs {
  flex-grow: 1;
}

.-\38 IA7a_summary {
  margin: 0 calc(var(--jpo-spacing) * -1);

  & > form > div {
    margin-top: 0;
  }
}

.-\38 IA7a_summaryBeingEdited {
  max-width: 390px;
  margin-left: 0;
  margin-top: var(--jpo-spacing);
  margin-bottom: var(--jpo-spacing);
}

.-\38 IA7a_summaryReadView {
  padding: var(--jpo-spacing) 6px;
  word-break: break-word;
  position: relative;
}

.-\38 IA7a_description {
  margin: calc(var(--jpo-spacing) * 3) 0 10px;
}

.-\38 IA7a_descriptionLabel {
  margin-bottom: var(--jpo-spacing);
}

.-\38 IA7a_descriptionLabelInner {
  padding-right: var(--jpo-spacing);
  display: inline-block;
  position: relative;
}

.-\38 IA7a_descriptionSpinner {
  margin-bottom: calc(var(--jpo-spacing) * 3);
}

.-\38 IA7a_hiddenButton {
  visibility: hidden;
}

.N8JWya_marker {
  cursor: pointer;
  flex-flow: column;
  align-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.N8JWya_icon {
  height: 24px;

  & button {
    width: 24px;
    height: 24px;
  }
}

.N8JWya_numberOld {
  color: var(--ds-surface, var(--adg3-color-N0));
  font-size: 8px;
  font-weight: var(--ds-font-weight-bold, 700);
  pointer-events: none;
  position: absolute;
  bottom: 6px;
}

.N8JWya_number {
  height: 100%;
  color: var(--ds-surface, var(--adg3-color-N0));
  font-size: 8px;
  font-weight: var(--ds-font-weight-bold, 700);
  pointer-events: none;
  align-items: center;
  display: flex;
  position: absolute;
}

.N8JWya_timeline-highlight {
  z-index: -1;
  border: 1px solid;
  height: 70%;
  position: absolute;
  top: 50%;
  left: calc(50% - 1px);

  &.N8JWya_released {
    border-color: var(--ds-icon-subtle, var(--adg3-color-N70));
  }

  &.N8JWya_releasedSpa {
    border-color: var(--ds-border-success, var(--adg3-color-G400));
  }

  &.N8JWya_unreleased, &.N8JWya_onTrack {
    border-color: var(--ds-border-success, var(--adg3-color-G300));
  }

  &.N8JWya_unreleasedSpa, &.N8JWya_onTrackSpa {
    border-color: var(--ds-border-information, var(--adg3-color-B400));
  }

  &.N8JWya_offTrack {
    border-color: var(--ds-border-danger, var(--adg3-color-R300));
  }

  &.N8JWya_offTrackSpa {
    border-color: var(--ds-border-danger, var(--adg3-color-R400));
  }
}

.N8JWya_releaseTooltip {
  align-items: center;
  gap: 4px;
  display: flex;
}

.N8JWya_releaseTooltipName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.N8JWya_releaseTooltipDate {
  color: var(--ds-background-accent-gray-subtler, var(--adg3-color-N50));
}

.N8JWya_button {
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  padding: 0;
}

.h7JH4W_main {
  top: var(--jpo-common-base-spacing);
  position: absolute;
}

.jzvgOW_main {
  z-index: 10;
  width: 100%;
  height: 40px;
  position: relative;
  top: 50px;

  &.jzvgOW_transposed {
    z-index: unset;
    top: 0;
  }
}

.esbLaW_timeUnitsBar {
  width: 100%;
  height: 100%;
  position: absolute;
}

.esbLaW_timeUnitsBarTop {
  height: 25px;
  display: flex;
}

.esbLaW_timeUnitsBarTopUnit {
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  font-size: 12px;
  font-weight: var(--ds-font-weight-semibold, 600);
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  height: 25px;
  line-height: 25px;

  &.esbLaW_newOverride {
    color: var(--ds-text-subtle, var(--adg3-color-N400));
    text-transform: none;
  }

  &.esbLaW_infiniteAxis {
    position: absolute;
  }
}

.esbLaW_timeUnitsBarTopUnitContent {
  padding: 0px var(--jpo-common-base-spacing);
  position: absolute;

  &.esbLaW_withLeftBorder {
    border-left: 1px solid var(--ds-border, var(--adg3-color-N50));
    box-sizing: border-box;
  }

  &.esbLaW_staticAxis {
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: inherit;
    overflow: hidden;
  }
}

.esbLaW_timeUnitsBarBottom {
  height: 25px;
  display: flex;
}

.esbLaW_timeUnitsBarBottomUnit {
  position: absolute;
}

.esbLaW_timeUnitsBarBottomUnitContent {
  border-bottom: 1px solid var(--ds-border, var(--adg3-color-N50));
  box-sizing: border-box;
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  height: 25px;
  font-size: 12px;
  line-height: 25px;

  &.esbLaW_newOverride {
    color: var(--ds-text-subtle, var(--adg3-color-N400));
    text-transform: none;
  }

  &.esbLaW_staticAxis {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.esbLaW_withLeftBorder {
    border-left: 1px solid var(--ds-border, var(--adg3-color-N50));
    box-sizing: border-box;
  }
}

.esbLaW_dateLozenge {
  font-weight: var(--ds-font-weight-regular, 400);
}

.esbLaW_timeUnitsBarContent {
  padding: 0px var(--jpo-common-base-spacing);
  text-transform: none;
  font-weight: var(--ds-font-weight-semibold, 600);
  justify-content: center;
  align-items: center;
  display: flex;

  &.esbLaW_withLeftBorder {
    border-left: 1px solid var(--ds-border, var(--adg3-color-N50));
    box-sizing: border-box;
  }

  &.esbLaW_staticAxis {
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: inherit;
    overflow: hidden;
  }
}

.esbLaW_yearWrapper {
  text-transform: none;
  justify-content: space-evenly;
  display: flex;
  overflow: clip;
}

.cVvj2G_main {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.cVvj2G_withRightBorder {
  border-right: 1px solid var(--ds-border, var(--adg3-color-N40));
  box-sizing: border-box;
}

.W3EHoW_infiniteAxisTimeline {
  width: 100%;
  height: 100%;
  position: absolute;
}

.Z6eima_unit {
  border-right: 1px solid var(--ds-border, var(--adg3-color-N40));
  box-sizing: border-box;
  height: 100%;
  position: absolute;

  &.Z6eima_highlighted {
    background-color: var(--ds-background-neutral, var(--adg3-color-N20A));
  }
}

.VTbVHG_marker {
  z-index: 4;
  pointer-events: none;
  border: 1px solid;
  height: 100%;
  position: absolute;

  &.VTbVHG_transposed {
    z-index: unset;
    top: 0;
  }

  &.VTbVHG_releasedSpa {
    border-color: var(--ds-border-success, var(--adg3-color-G400));
  }

  &.VTbVHG_unreleasedSpa, &.VTbVHG_onTrackSpa {
    border-color: var(--ds-border-information, var(--adg3-color-B400));
  }

  &.VTbVHG_offTrackSpa {
    border-color: var(--ds-border-danger, var(--adg3-color-R400));
  }
}

.zWKwda_root {
  cursor: pointer;
  pointer-events: initial;
  z-index: var(--jpo-z-index-issue-arrow);
  position: absolute;
}

.X8NtBq_spaBar {
  user-select: none;
  min-width: 3px;
  height: 24px;
  z-index: var(--jpo-z-index-dependency-bar);
  border-radius: 4px;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.X8NtBq_content {
  flex: auto;
}

.X8NtBq_leftHandleOld, .X8NtBq_rightHandleOld {
  background-color: var(--ds-background-inverse-subtle-pressed, #ffffff4d);
  cursor: ew-resize;
  user-select: none;
  height: 100%;
  z-index: var(--jpo-z-index-dependency-handle);
  flex: 0 0 10px;
  display: inline-block;
}

.X8NtBq_leftHandle, .X8NtBq_rightHandle {
  background-color: var(--ds-background-inverse-subtle-pressed, #ffffff4d);
  cursor: ew-resize;
  user-select: none;
  height: 16px;
  z-index: var(--jpo-z-index-dependency-handle);
  border-radius: 3px;
  flex: 0 0 4px;
  margin: 4px;
  display: inline-flex;
}

.X8NtBq_stripes {
  position: absolute;
  inset: 0;
}

.X8NtBq_flyoutPositioner {
  position: absolute;
}

.X8NtBq_flyoutContent {
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  height: 20px;
  display: flex;
}

.Kx8ltq_dateField {
  user-select: none;
  white-space: nowrap;
  color: var(--ds-text-inverse, var(--adg3-color-N0));
  background-color: var(--ds-background-neutral-bold, var(--adg3-color-N800));
  border: 1px solid var(--ds-background-neutral-bold, var(--adg3-color-N800));
  border-radius: 3px;
  height: 20px;
  padding: 0 5px;
}

.NRG5Ra_dateTooltipContent {
  pointer-events: none;
  justify-content: space-between;
  display: flex;
}

.NRG5Ra_dateTooltipTrigger {
  top: var(--space-100, 8px);
  height: var(--space-300, 24px);
  visibility: hidden;
  justify-content: space-between;
  display: flex;
  position: absolute;
}

.NRG5Ra_dateTooltip {
  z-index: var(--jpo-z-index-date-tooltip);
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: 32px;
}

.LcPLja_markerOld {
  user-select: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  overflow: hidden;
}

.LcPLja_marker {
  user-select: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
}

.LcPLja_marker-default > span {
  color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.LcPLja_marker-offtrack > span {
  color: var(--ds-text-danger, var(--adg3-color-R400));
}

.LcPLja_marker-none > span {
  color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.LcPLja_marker-dragging {
  visibility: hidden;
  outline: none;
}

.LcPLja_hitbox-left {
  margin-right: -4px;
  padding-right: 4px;
}

.LcPLja_hitbox-right {
  margin-left: -4px;
  padding-left: 4px;
}

._3OyI6W_dependencies {
  user-select: none;
  position: absolute;

  &._3OyI6W_start {
    text-align: right;
  }

  &._3OyI6W_unhovered {
    z-index: var(--jpo-z-index-dependency-marker);
  }

  &._3OyI6W_hovered {
    z-index: var(--jpo-z-index-dependency-marker-hovered);
  }
}

._3OyI6W_badge-count {
  font-size: 10px;
  line-height: 16px;
}

.O2em4q_issueLength {
  user-select: none;
  white-space: nowrap;
  background-color: var(--ds-surface-sunken, var(--adg3-color-N20));
  border: 1px solid var(--ds-surface-sunken, var(--adg3-color-N20));
  border-radius: 3px;
  padding: 0 5px;
}

.O2em4q_withSprint, .O2em4q_sprintDetails {
  display: flex;
}

.O2em4q_separator {
  background-color: var(--ds-background-neutral-bold, var(--adg3-color-N600));
  border-radius: 50%;
  align-self: center;
  width: 5px;
  height: 5px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
}

.Avp6xa_spaBothDatesRow {
  height: 100%;
  padding-top: 8px;
}

.fUXzxa_spaNoDatesRow {
  cursor: copy;
  height: 100%;
  padding-top: 8px;

  &.fUXzxa_readOnly {
    cursor: default;
  }
}

.fUXzxa_spaBarContent {
  text-align: center;
  position: relative;
  top: 3px;
}

.NZ8FpW_spaPartialDatesRow {
  height: 100%;
  padding-top: 8px;
}

.n2DYDa_row {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  position: relative;
}

.BCIj2a_sprintTooltip {
  box-sizing: content-box;
  background-color: var(--ds-background-neutral-bold, var(--adg3-color-N800));
  color: var(--ds-text-inverse, var(--adg3-color-N0));
  border-radius: 4px;
  margin-top: 12px;
  padding: 2px 6px;
  font-size: 12px;
}

.VhGm0W_toggle {
  cursor: pointer;
  outline: none;
}

.WZML7W_changeIndicator {
  position: relative;
  top: 5px;
  right: 2px;
}

._6YIzVa_sprintLabels {
  white-space: nowrap;
  width: 100%;
  height: 40px;
  display: flex;
  overflow: hidden;
}

._6YIzVa_message {
  color: var(--ds-text-subtle, var(--adg3-color-subtleText-dark));
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px;
  overflow: hidden;
}

._6YIzVa_bar {
  background-color: var(--ds-surface, var(--adg3-color-N0));
  border-radius: 3px;
  align-items: flex-end;
  min-width: 5px;
  height: 20px;
  margin: 0 5px;
  display: flex;

  & > div {
    border-radius: 5px;
    width: 100%;

    &._6YIzVa_okay {
      background-color: var(--ds-background-success-bold, var(--adg3-color-G300));
    }

    &._6YIzVa_overbooked {
      background-color: var(--ds-background-danger-bold, var(--adg3-color-R300));
    }
  }
}

._6YIzVa_sprintBox {
  box-sizing: border-box;
  cursor: pointer;
  background-color: var(--ds-background-neutral-hovered, var(--adg3-color-N40));
  height: 30px;
  color: var(--ds-text-subtle, var(--adg3-color-N400));
  border-radius: 3px;
  align-items: center;
  margin: 5px 2px;
  display: flex;

  &:hover {
    background-color: var(--ds-background-neutral-pressed, var(--adg3-color-N60));
  }

  &._6YIzVa_overbooked {
    background-color: var(--ds-background-danger, var(--adg3-color-R50));
    color: var(--ds-text-danger, var(--adg3-color-R500));

    &:hover {
      background-color: var(--ds-background-danger-hovered, var(--adg3-color-R75));
    }
  }

  &._6YIzVa_inconsistent {
    background-color: var(--ds-background-warning, var(--adg3-color-Y75));
    color: var(--ds-text-warning, var(--jpo-warning-color));

    &:hover {
      background-color: var(--ds-background-warning-hovered, var(--adg3-color-Y100));
    }
  }

  &._6YIzVa_activeSprint {
    background-color: var(--ds-background-selected, var(--adg3-color-B50));
    color: var(--ds-text-selected, var(--adg3-color-B500));

    &:hover {
      background-color: var(--ds-background-selected-hovered, var(--adg3-color-B75));
    }
  }

  &._6YIzVa_pastSprint, &._6YIzVa_closedSprint {
    background-color: var(--ds-background-neutral, var(--adg3-color-N20));
    color: var(--ds-text-disabled, var(--adg3-color-N60));

    &:hover {
      background-color: var(--ds-background-neutral-hovered, var(--adg3-color-N30));
    }
  }
}

._6YIzVa_sprintName {
  font-size: 12px;
  font-weight: var(--ds-font-weight-bold, 700);
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: auto;
  overflow: hidden;
}

.FP9-Ka_unit {
  box-sizing: border-box;
  height: 100%;
  color: var(--ds-text-subtlest, var(--adg3-color-N200));
  display: inline-block;
}

.FP9-Ka_innerContent {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 10px 5px;
  overflow: hidden;
}

.wHWzja_sprint {
  outline: none;
  display: inline-block;

  & > div {
    width: 100%;
  }
}

.wHWzja_bar {
  background-color: var(--ds-surface, var(--adg3-color-N0));
  border-radius: 3px;
  align-items: flex-end;
  min-width: 5px;
  height: 20px;
  margin: 0 5px;
  display: flex;

  & > div {
    border-radius: 5px;
    width: 100%;

    &.wHWzja_okay {
      background-color: var(--ds-background-success-bold, var(--adg3-color-G300));
    }

    &.wHWzja_overbooked {
      background-color: var(--ds-background-danger-bold, var(--adg3-color-R300));
    }
  }
}

.wHWzja_isContiguousWithNextSprint:before {
  border-top: 15px solid var(--ds-surface, var(--adg3-color-N0));
  border-bottom: 15px solid var(--ds-surface, var(--adg3-color-N0));
  content: "";
  border-left: 8px solid;
  position: absolute;
  top: 5px;
  right: 2px;
}

.wHWzja_sprintBoxWrapper {
  background-color: var(--ds-surface, var(--adg3-color-N0));
}

.wHWzja_sprintBox {
  box-sizing: border-box;
  cursor: pointer;
  background-color: var(--ds-background-accent-gray-subtler, var(--adg3-color-N40));
  height: 30px;
  color: var(--ds-text-subtle, var(--adg3-color-N400));
  border-radius: 3px;
  align-items: center;
  margin: 5px 2px;
  display: flex;

  &.wHWzja_isContiguousWithNextSprint:before {
    border-left-color: var(--ds-background-accent-gray-subtler, var(--adg3-color-N40));
  }

  &:hover {
    background-color: var(--ds-background-neutral-pressed, var(--adg3-color-N60));
  }

  &.wHWzja_overbooked {
    background-color: var(--ds-background-danger, var(--adg3-color-R50));
    color: var(--ds-text-danger, var(--adg3-color-R500));

    &.wHWzja_isContiguousWithNextSprint:before {
      border-left-color: var(--ds-background-danger, var(--adg3-color-R50));
    }

    &:hover {
      background-color: var(--ds-background-danger-hovered, var(--adg3-color-R75));
    }
  }

  &.wHWzja_inconsistent {
    background-color: var(--ds-background-warning, var(--adg3-color-Y75));
    color: var(--ds-text-warning, var(--jpo-warning-color));

    &.wHWzja_isContiguousWithNextSprint:before {
      border-left-color: var(--ds-background-warning, var(--adg3-color-Y75));
    }

    &:hover {
      background-color: var(--ds-background-warning-hovered, var(--adg3-color-Y100));
    }
  }

  &.wHWzja_activeSprint {
    background-color: var(--ds-background-selected, var(--adg3-color-B50));
    color: var(--ds-text-selected, var(--adg3-color-B500));

    &.wHWzja_isContiguousWithNextSprint:before {
      border-left-color: var(--ds-background-selected, var(--adg3-color-B50));
    }

    &:hover {
      background-color: var(--ds-background-selected-hovered, var(--adg3-color-B75));
    }
  }

  &.wHWzja_pastSprint, &.wHWzja_closedSprint {
    background-color: var(--ds-background-neutral, var(--adg3-color-N20));
    color: var(--ds-text-disabled, var(--adg3-color-N60));

    &.wHWzja_isContiguousWithNextSprint:before {
      border-left-color: var(--ds-background-neutral, var(--adg3-color-N20));
    }

    &:hover {
      background-color: var(--ds-background-neutral-hovered, var(--adg3-color-N30));
    }
  }
}

.wHWzja_sprintTooltip {
  text-align: center;
  flex-direction: column;
  display: flex;
}

.wHWzja_sprintName {
  font-size: 12px;
  font-weight: var(--ds-font-weight-bold, 700);
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: auto;
  overflow: hidden;
}

.wHWzja_filtered {
  outline: none;
  flex: none;
  display: inline-block;
}

.wHWzja_changeIndicator {
  z-index: 1;
  position: relative;
  top: 5px;
}

.gT7APG_sprintLabels {
  white-space: nowrap;
  width: 100%;
  height: 40px;
  display: flex;
  overflow: hidden;
}

.gT7APG_message {
  color: var(--ds-text-subtlest, var(--adg3-color-subtleText-dark));
  padding: var(--jpo-common-base-spacing);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
/*# sourceMappingURL=shared.967c424b.css.map */
